import { Either } from '@ajtec/either-typescript';
import { IDevice } from '../entities/device';
import { IDeviceRepository } from '../repository/device-repository';

export class GetUserProducts {
	repository: IDeviceRepository;

	constructor(deviceRepository: IDeviceRepository) {
		this.repository = deviceRepository;
	}

	call(): Promise<Either<string, IDevice[]>> {
		return this.repository.getUserProducts();
	}
}
