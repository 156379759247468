import React from 'react';
import Dimens from 'assets/dimens';
import {
	Box, TextField, TextFieldProps, styled,
} from '@mui/material';
import BodyText from '../body-text/BodyText';

const StyledTextField = styled(TextField)(({ theme }) => ({

	input: {
		color: theme.palette.textMain.main,
		'&::placeholder': {
			opacity: 1,
			fontSize: Dimens.BODY_TEXT_M,
		},
	},
	'& label.Mui-focused': {
		color: theme.palette.primary.main,
	},
	'& .MuiInput-underline:before': {
		borderBottomColor: theme.palette.textMain.main,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: theme.palette.primary.main,
	},
	'&& .MuiInput-root:hover::before': {
		borderColor: theme.palette.primary.main,
	},
}));

const DefaultInput: React.FC<TextFieldProps> = (props) => {
	const { label, sx, ...remainingProps } = props;
	return (
		<Box>
			<BodyText size={Dimens.BODY_TEXT_S}>{label}</BodyText>
			<StyledTextField variant="standard" size="small" fullWidth sx={{ mt: 2, fontSize: Dimens.BODY_TEXT_M, ...sx }} {...remainingProps} />
		</Box>
	);
};

export default DefaultInput;
