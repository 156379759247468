import React, { useRef } from 'react';
import {
	Box, Slide, Stack, useScrollTrigger, useTheme,
} from '@mui/material';
import { BodyText, DefaultButton } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const HideOnScroll = (props: Props) => {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
};

const BuyProduct = () => {
	const theme = useTheme();
	const { mixins } = useTheme();
	const boxInnerRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation('translations');

	const handleBuyClick = () => {
		const access = document.getElementById('energy-efficiency-controller-phases');
		access?.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	const handleTechSpecsClick = () => {
		const access = document.getElementById('technical-specification');
		access?.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	return (
		<div
			ref={boxInnerRef}
		>
			<HideOnScroll>

				<Box sx={{
				// height: '85px',
					mt: `${parseInt(String(mixins.toolbar.minHeight), 10) + 65}px`,
					position: 'fixed',
					pt: Dimens.BUTTON_PADDING_HORIZANTAL,
					pb: Dimens.BUTTON_PADDING_HORIZANTAL,
					width: '100%',
					borderTop: `1px solid ${theme.palette.primary.main}`,
					borderBottom: `1px solid ${theme.palette.primary.main}`,
					background: theme.palette.background.default,
					display: { md: 'flex', xs: 'none' },
					justifyContent: 'flex-end',
					alignItems: 'center',
					top: 0,
					pr: Dimens.PAGE_PADDING,
					zIndex: 9999.0,
					// ...(isReachTop ? sxFixed : {}),
				}}
				>
					<Stack direction={{ md: 'row', xs: 'column' }} gap={5} alignItems="center">
						<BodyText sx={{ color: theme.palette.primary.main }}>{t('user_manual')}</BodyText>
						<BodyText sx={{ color: theme.palette.primary.main, cursor: 'pointer' }} onClick={handleTechSpecsClick}>{t('tech_specs')}</BodyText>
						<DefaultButton
							onClick={handleBuyClick}
						>
							{t('buy_product')}
						</DefaultButton>
					</Stack>
				</Box>
			</HideOnScroll>
		</div>
	);
};
export default BuyProduct;
