import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import React from 'react';

interface Props {
	title: string;
	description?: string;
}

const TechSpecListItem: React.FC<Props> = (props) => {
	const { title, description } = props;
	return (
		<BodyText sx={{
			fontSize: Dimens.BODY_TEXT_S,
			fontWeight: Dimens.FONT_WEIGHT_LIGHT,
		}}
		>
			{title}
			:
			{' '}
			{description}
		</BodyText>
	);
};

export default TechSpecListItem;
