import { Either } from '@ajtec/either-typescript';
import { EnergyConsumptionYearParams, IEnergyConsumptionPeriod } from '../entities/energy-consumption-period';
import { IEnergyConsumptionRepository } from '../repository/energy-consumption-repository';

export class GetEnergyConsumptionYear {
	repository: IEnergyConsumptionRepository;

	constructor(deviceDetailRepository: IEnergyConsumptionRepository) {
		this.repository = deviceDetailRepository;
	}

	call(params: EnergyConsumptionYearParams): Promise<Either<string, IEnergyConsumptionPeriod[]>> {
		return this.repository.getEnergyConsumptionByYear(params);
	}
}
