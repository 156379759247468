import { Either } from '@ajtec/either-typescript';
import { EnergyConsumptionWeekParams, IEnergyConsumptionPeriod } from '../entities/energy-consumption-period';
import { IEnergyConsumptionRepository } from '../repository/energy-consumption-repository';

export class GetEnergyConsumptionByWeek {
	repository: IEnergyConsumptionRepository;

	constructor(energyConsumptionRepository: IEnergyConsumptionRepository) {
		this.repository = energyConsumptionRepository;
	}

	call(params: EnergyConsumptionWeekParams): Promise<Either<string, IEnergyConsumptionPeriod[]>> {
		return this.repository.getEnergyConsumptionByWeek(params);
	}
}
