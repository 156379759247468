/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-danger */
import Dimens from 'assets/dimens';
import {
	BodyText, DefaultButton, TitleText,
} from 'common/presentation/components';
import {
	Box, Card, Stack,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDown } from 'assets/icons';
import { Link } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';

const htmlScaffolding = (id: string, model: any, height: string, width: string) => `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${id}</title>
</head>
<style>
    #${id} {
      width: ${width};
      height: ${height};
    }
    </style>
<body>
    <model-viewer 
	id="${id}"
        src=${model}
		cameraOrbit={0deg 0deg 0}
        shadow-intensity="5"
        magic-leap
        >
    </model-viewer>
</body>
</html>`;

interface Props {
	title: string;
	info: string;
	model: any;
	color: string;
	contrastText?: string;
	id: string;
	width: string;
	height: string;
}

const ProductCard: React.FC<Props> = (props) => {
	const { t } = useTranslation('translations');
	const {
		title, info, model, color, contrastText, id, width, height,
	} = props;

	React.useEffect(() => {
		const modelViewer = document.getElementById(id) as any;
		const productCard = document.getElementById(`product-card-${id}`);
		let rotation = 0;
		let timer: NodeJS.Timeout;
		const timeOut = 10;
		modelViewer.cameraOrbit = '0deg 50deg 0';

		const handleMouseOver = () => {
			const rotationSpeed = 1;
			rotation += rotationSpeed;
			if (modelViewer && rotation < 90) {
				modelViewer.cameraOrbit = `${rotation}deg 50deg 0`;
			}
			if (rotation === 90) {
				clearTimeout(timer);
			}
			timer = setTimeout(handleMouseOver, timeOut);
		};

		const handleMouseOut = () => {
			if (modelViewer) {
				modelViewer.cameraOrbit = '0deg 50deg 105%';
				rotation = 0;
			}
			clearTimeout(timer);
		};

		productCard?.addEventListener('mouseover', handleMouseOver);
		productCard?.addEventListener('mouseout', handleMouseOut);

		return () => {
			productCard?.removeEventListener('mouseover', handleMouseOver);
			productCard?.removeEventListener('mouseout', handleMouseOut);
			clearTimeout(timer);
		};
	}, []);

	return (
		<Card
			elevation={0}
			id={`product-card-${id}`}
			sx={{
				pt: { md: Dimens.CARD_PADDING_LARGE, xs: Dimens.CARD_PADDING_LARGE_MOBILE },
				pl: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE },
				pb: '12px',
				bgcolor: color,
				width: { md: '48%', xs: '100%' },
				height: { md: Dimens.PRODUCT_CARD_HEIGHT, xs: Dimens.PRODUCT_CARD_HEIGHT_MOBILE },
				borderRadius: Dimens.VIDEO_BORDER_RADIUS,
			}}
		>
			<Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
				<Box sx={{ pr: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE } }}>
					<TitleText
						color={contrastText}
						sx={{
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
						}}
					>
						{t(title)}
					</TitleText>
					<BodyText
						size={Dimens.BODY_TEXT_M}
						color={contrastText}
						sx={{ mt: { md: 4, xs: 2 }, fontWeight: Dimens.FONT_WEIGHT_LIGHTER }}
					>
						{t(info)}
					</BodyText>
					<Box sx={{ mt: { md: 4, xs: 2 } }}>
						<Link to={AppLinks.PRODUCT} style={{ textDecoration: 'none' }}>
							<DefaultButton endIcon={<ArrowDown />}>{t('learn_more')}</DefaultButton>
						</Link>
					</Box>
				</Box>
				<Stack direction="row" justifyContent={{ md: 'flex-end', xs: 'center' }} alignItems={{ md: 'center', xs: 'flex-start' }}>
					<Box
						id="modelContainer"
						sx={{
							width, height, mr: 4,
						}}
						dangerouslySetInnerHTML={{
							__html: htmlScaffolding(id, model, height, width),
						}}
					/>
				</Stack>
			</Stack>
		</Card>
	);
};

export default ProductCard;
