/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import {
	AppBar as MuiAppBar,
	Box, Stack, Toolbar,
	Typography, IconButton,
	SvgIcon, useMediaQuery, Theme, Slide, useScrollTrigger, Badge,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import {
	Cart, HambergerMenu, logo, logoMobile,
} from 'assets/icons';
import Dimens from 'assets/dimens';
import { Link, useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import LanguageSelector from '../language-selector/LanguageSelector';
import DefaultButton from '../default-button/DefaultButton';
import useEffectCustom from '../../hooks/useEffectCustom';
import useCart from '../../providers/cart.provider';
import NavMenuMobile from '../nav-menu-mobile/NavMenuMobile';

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

const HideOnScroll = (props: Props) => {
	const { children, window } = props;
	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	});

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
};

const Appbar = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { cartProducts } = useCart();

	const [appLogo, setAppLogo] = React.useState(logo);
	const [navMenuOpen, setNavMenuOpen] = React.useState(false);
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
	const [logoWidth, setLogoWidth] = React.useState('');

	const RouteText = styled(Typography)(({ theme }) => ({
		color: theme.palette.primary.main,
		cursor: 'pointer',
		fontWeight: Dimens.FONT_WEIGHT_NORMAL,
		fontSize: Dimens.TEXT_DEFAULT,
	}));

	const linkStyle = {
		textDecoration: 'none',
	};

	useEffectCustom(() => {
		if (isMd) {
			setAppLogo(logo);
			setLogoWidth('');
		} else {
			setAppLogo(logoMobile);
			setLogoWidth('50px');
		}
	}, [isMd]);

	const handleClientAreaClick = () => {
		navigate(AppLinks.CLIENT_AREA_LOGIN);
	};

	const handleOpenNavMenu = () => {
		setNavMenuOpen(true);
	};

	const handleNavMenuClose = () => {
		setNavMenuOpen(false);
	};

	return (
		<header>
			<HideOnScroll>
				<Box sx={{ flexGrow: 1 }}>
					<MuiAppBar
						position="fixed"
						color="inherit"
						elevation={0}
						sx={{
							pt: 3,
							pr: {
								md: Dimens.APP_BAR_PADDING_HORIZANTAL,
								xs: Dimens.PAGE_PADDING_MOBILE,
							},
							pl: {
								md: Dimens.APP_BAR_PADDING_HORIZANTAL,
								xs: Dimens.PAGE_PADDING_MOBILE,
							},
						}}
					>
						<Toolbar disableGutters>
							<Box sx={{
								display: 'flex',
								flex: 1,
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
							>
								<Box sx={{ mr: 1 }}>
									<Link to={AppLinks.LANDING}>
										<img src={appLogo} width={logoWidth} alt="logo" />
									</Link>
								</Box>
								<Box sx={{ display: { md: 'none', xs: 'flex' }, alignItems: 'center' }}>
									<Link to={AppLinks.CHECKOUT_PAGE} style={{ ...linkStyle, marginRight: '10px' }}>
										<Badge badgeContent={cartProducts.length} color="primary">
											<SvgIcon>
												<Cart />
											</SvgIcon>
										</Badge>
									</Link>
									<IconButton color="inherit" onClick={handleOpenNavMenu}>
										<HambergerMenu />
									</IconButton>
								</Box>
								<Box sx={{
									display: { md: 'flex', xs: 'none' },
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									flex: 1,
								}}
								>
									<Stack
										direction="row"
										justifyContent="space-evenly"
										sx={{
											flex: 1,
										}}
									>
										<Link to={AppLinks.PRODUCT} style={linkStyle}>
											<RouteText>{t('energy_analyzer')}</RouteText>
										</Link>
										<Link to={AppLinks.MOBILE_APP} style={linkStyle}>
											<RouteText>{t('mobile_app')}</RouteText>
										</Link>
										{/* <Link to={AppLinks.COMMUNITY} style={linkStyle}>
											<RouteText>{t('company')}</RouteText>
										</Link> */}
										<Link to={AppLinks.COMMUNITY} style={linkStyle}>
											<RouteText>{t('community')}</RouteText>
										</Link>
										<Link to={AppLinks.CONTACT_US} style={linkStyle}>
											<RouteText>{t('contact_us')}</RouteText>
										</Link>
										<Link to={AppLinks.CHECKOUT_PAGE} style={{ ...linkStyle, marginRight: '10px' }}>
											<Badge badgeContent={cartProducts.length} color="primary">
												<SvgIcon>
													<Cart />
												</SvgIcon>
											</Badge>
										</Link>
									</Stack>
									<DefaultButton variant="contained" onClick={handleClientAreaClick}>{t('client_area')}</DefaultButton>
									<Box sx={{ ml: 10 }}>
										<LanguageSelector />
									</Box>
								</Box>
							</Box>
						</Toolbar>
					</MuiAppBar>
				</Box>
			</HideOnScroll>
			<NavMenuMobile open={navMenuOpen} handleClose={handleNavMenuClose} />
		</header>
	);
};

export default React.memo(Appbar);
