import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import { ArrowBackIos } from '@mui/icons-material';
import BodyText from '../body-text/BodyText';

interface Props {
	onBackClick: () => void
}

const ClientBackButton: FC<Props> = (props) => {
	const { t } = useTranslation('translations');
	const { onBackClick } = props;
	return (
		<Stack
			direction="row"
			onClick={onBackClick}
			alignItems="center"
			sx={{
				cursor: 'pointer',
				width: Dimens.BACK_BUTTON_WIDTH,
			}}
		>
			<ArrowBackIos />
			<BodyText sx={{ fontSize: Dimens.BUTTON_TEXT }}>{t('previous').toUpperCase()}</BodyText>
		</Stack>
	);
};

export default ClientBackButton;
