export const validateEmail = (email: string) => {
	const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	const result = emailRegex.test(email);
	return result;
};

export const validatePassword = (password: string) => {
	const passwordRegex = /^(?=.*\d)(?=.*[a-z]).{6,20}$/;
	const result = passwordRegex.test(password);
	return result;
};
