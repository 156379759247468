import { Either } from '@ajtec/either-typescript';
import { ILoginRepository } from '../repository/login-repository';
import { IAlert } from '../entities/alert';

export class GetAlerts {
	repository: ILoginRepository;

	constructor(loginRepository: ILoginRepository) {
		this.repository = loginRepository;
	}

	call(): Promise<Either<string, IAlert[]>> {
		return this.repository.getAlerts();
	}
}
