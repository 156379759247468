import { Either, Left, Right } from '@ajtec/either-typescript';
import { IOrderRequestParams } from '../../domain/entities/order';
import { IOrderRepository } from '../../domain/repository/order-repository';
import { OrderApiImpl } from '../remote/order-api-impl';
import { ApiResponse } from 'common/data/entities/api-response';

export class COrderRepositoryImpl implements IOrderRepository {
	orderApi = new OrderApiImpl();

	async createOrder(params: IOrderRequestParams): Promise<Either<string, ApiResponse<string>>> {
		try {
			const order = await this.orderApi.createOrder(params);
			return new Right(order);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
