import React from 'react';
import {
	Box,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { worldmap } from 'assets/images';
import { BodyText, TitleText } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';

const CommunitySection = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mb: Dimens.FOOTER_PADDING_VERTICAL,
				mt: Dimens.SECTION_SPACING,
			}}
			>
				<Box sx={{
					height: Dimens.COMMUNITY_IMAGE_HEIGHT,
					width: '100%',
					borderRadius: Dimens.VIDEO_BORDER_RADIUS,
					backgroundImage: `url(${worldmap})`,
					pt: Dimens.CARD_PADDING_LARGE,
					pl: { md: Dimens.CARD_PADDING_LARGE, xs: Dimens.CARD_PADDING_LARGE_MOBILE },
				}}
				>
					<TitleText
						sx={{
							width: { md: '85%', xs: '95%' },
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
						}}
					>
						{t('save_planet_title').toUpperCase()}
					</TitleText>
					<BodyText
						sx={{
							width: { md: '85%', xs: '95%' },
							mt: 5,
							fontWeight: Dimens.FONT_WEIGHT_LIGHT,
							fontSize: { md: Dimens.BODY_TEXT_ML, xs: Dimens.BODY_TEXT_ML_MOBILE },
						}}
					>
						{t('save_planet_title_info_one')}
						{' '}
					</BodyText>
					<BodyText
						sx={{
							width: { md: '85%', xs: '95%' },
							mt: 3,
							fontWeight: Dimens.FONT_WEIGHT_LIGHT,
							fontSize: { md: Dimens.BODY_TEXT_ML, xs: Dimens.BODY_TEXT_ML_MOBILE },
						}}
					>
						{t('save_planet_title_info_two')}
						{' '}
					</BodyText>
				</Box>
			</Box>
		</section>
	);
};

export default CommunitySection;
