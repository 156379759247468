import Dimens from 'assets/dimens';
import {
	Box, Stack, SvgIcon,
} from '@mui/material';
import React from 'react';
import { DefaultButton } from 'common/presentation/components';
import { ArrowBack } from '@mui/icons-material';
import { Arrowforwardsquare } from 'assets/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CommunityDetailHero = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	const handleGoBack = () => {
		navigate(-1);
	};
	return (
		<section>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
				// mt: Dimens.SECTION_SPACING,
			}}
			>
				<Stack direction="row" justifyContent="space-between">
					<DefaultButton variant="text" startIcon={<ArrowBack />} onClick={handleGoBack} endIcon={<div />}>{t('back_to_blog')}</DefaultButton>
					<DefaultButton variant="text" endIcon={<SvgIcon component="div"><Arrowforwardsquare /></SvgIcon>}>{t('share_post')}</DefaultButton>
				</Stack>
			</Box>

		</section>
	);
};

export default CommunityDetailHero;
