import { Left, Right } from '@ajtec/either-typescript';
import {
	EnergyConsumptionYearParams,
	EnergyConsumptionMonthParams,
	EnergyConsumptionWeekParams,
} from '../../domain/entities/energy-consumption-period';
import { IEnergyConsumptionRepository } from '../../domain/repository/energy-consumption-repository';
import { EnergyConsumptionApiImpl } from '../remote/energy-consumption-api-impl';

export class CEnergyConsumptionRepositoryImpl implements IEnergyConsumptionRepository {
	energyConsumptionApi = new EnergyConsumptionApiImpl();

	async getEnergyConsumptionByYear(params: EnergyConsumptionYearParams) {
		try {
			const energyConsumption = await this.energyConsumptionApi.getEnergyConsumptionByYear(params);
			return new Right(energyConsumption);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getEnergyConsumptionByMonth(params: EnergyConsumptionMonthParams) {
		try {
			const energyConsumption = await this.energyConsumptionApi.getEnergyConsumptionByMonth(params);
			return new Right(energyConsumption);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getEnergyConsumptionByWeek(params: EnergyConsumptionWeekParams) {
		try {
			const energyConsumption = await this.energyConsumptionApi.getEnergyConsumptionByWeek(params);
			return new Right(energyConsumption);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
