/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface Props {
	graphData: number[];
}

const ConsumptionChart: React.FC<Props> = (props) => {
	const { graphData } = props;

	const state = {
		series: [{
			data: graphData,
		}],
	};

	const options: ApexOptions = {
		chart: {
			type: 'bar',
			height: 430,
		},
		plotOptions: {
			bar: {
				barHeight: '99%',
				distributed: true,
				horizontal: false,
				dataLabels: {
					position: 'top',
				},
				borderRadius: 11.5,
			},
		},
		colors: ['#4DFFFF', '#7F7F96', '#23233F',
		],
		dataLabels: {
			enabled: true,
			offsetX: -6,
			style: {
				fontSize: '12px',
				colors: ['#000', '#fff', '#fff'],
			},
			formatter(val) {
				return `${val} €`;
			},
		},
		tooltip: {
			enabled: false,
		},
		legend: { show: false },
		xaxis: {
			categories: ['estimated', 'last_month', 'current'],
		},
		grid: {
			show: false,
			borderColor: '#7f7f96',
			strokeDashArray: 0,
			position: 'back',
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
			row: {
				colors: undefined,
				opacity: 1,
			},
			column: {
				colors: undefined,
				opacity: 1,
			},
		},
	};

	// const options = {

	// 	series: graphData,
	// 	options: {
	// 		chart: {
	// 			type: 'radialBar',
	// 		},
	// 		plotOptions: {
	// 			radialBar: {
	// 				dataLabels: {
	// 					name: {
	// 						fontSize: '22px',
	// 					},
	// 					value: {
	// 						fontSize: '16px',
	// 					},
	// 					total: {
	// 						show: true,
	// 						label: 'Total',
	// 						// formatter() {
	// 						// // By default this function returns the average of all series.
	// 						// // The below is just an example to show the use of custom formatter function
	// 						// 	return 249;
	// 						// },
	// 					},
	// 				},
	// 			},
	// 		},
	// 		labels: ['estimated', 'last_month', 'current'],
	// 	},

	// };

	return (
		<div>
			<ReactApexChart options={options} series={state.series} type="bar" height={430} />
		</div>
	);
};
export default ConsumptionChart;
