import React from 'react';
import BuyProduct from './BuyProduct';
import EnergyEfficiencyController from './EnergyEfficiencyController';
import EnergyEfficiencyControllerPhases from './EnergyEfficiencyControllerPhases';
import HowIokeeWorks from './HowIokeeWorks';
import IokeeFeatures from './IokeeFeatures';
import Setup from './Setup';
import TechnicalSpecification from './TechnicalSpecification';
import { CommunityForm } from 'common/presentation/components';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';

const ProductPage = () => (
	<main>
		<CommunityProvier>
			<EnergyEfficiencyControllerPhases />
			<BuyProduct />
			<EnergyEfficiencyController />
			<HowIokeeWorks />
			<IokeeFeatures />
			<Setup />
			<TechnicalSpecification />
			<CommunityForm />
		</CommunityProvier>
	</main>
);

export default ProductPage;
