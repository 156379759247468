/* eslint-disable class-methods-use-this */
import { reqPost } from 'common/data/remote/request';
import { IOrderRequestParams } from '../../domain/entities/order';
import { OrderApi } from './order-api';
import { Endpoints } from 'assets/Endpoints';
import { ApiResponse } from 'common/data/entities/api-response';

export class OrderApiImpl implements OrderApi {
	async createOrder(params: IOrderRequestParams): Promise<ApiResponse<string>> {
		try {
			const resp = await reqPost({
				url: Endpoints.ORDER,
				data: params,
			});
			const data = resp.data as ApiResponse<string>;
			return data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
