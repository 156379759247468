import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import { Box, useTheme } from '@mui/material';
import React from 'react';

interface Props {
	title: string
	isSelected?: boolean
	onClick: () => void
}

const ChoiceLabel: React.FC<Props> = (props) => {
	const { title, isSelected, onClick } = props;
	const theme = useTheme();
	return (
		<Box
			component="span"
			sx={{
				border: isSelected ? `1.5px solid ${theme.palette.primary.main}` : 'none',
				borderRadius: Dimens.OPTION_LABEL_BORDER_RADIUS,
				bgcolor: theme.palette.bgLight.main,
				p: Dimens.OPTION_LABEL_PADDING,
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<BodyText sx={{
				fontSize: Dimens.BODY_TEXT_M,
				fontWeight: Dimens.FONT_WEIGHT_NORMAL,
			}}
			>
				{title}
			</BodyText>
		</Box>
	);
};

export default ChoiceLabel;
