import { InclinedArrow } from 'assets/icons';
// import Dimens from 'assets/dimens';
import React from 'react';
import Dimens from 'assets/dimens';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { CircularProgress } from '@mui/material';

interface Props extends LoadingButtonProps {
	children: string
}

const DefaultButton: React.FC<Props> = (props) => {
	const { children, sx, ...rest } = props;
	return (
		<LoadingButton
			color="primary"
			variant="outlined"
			size="small"
			loadingIndicator={<CircularProgress color="primary" size={25} />}
			endIcon={<InclinedArrow />}
			sx={{
				pl: Dimens.BUTTON_PADDING_HORIZANTAL,
				pr: Dimens.BUTTON_PADDING_HORIZANTAL,
				pt: Dimens.BUTTON_PADDING_VERTICAL,
				pb: Dimens.BUTTON_PADDING_VERTICAL,
				borderRadius: 50 / 2,
				borderWidth: 1.5,
				fontSize: { md: Dimens.BUTTON_TEXT, xs: Dimens.BUTTON_TEXT_MOBILE },
				fontWeight: Dimens.FONT_WEIGHT_NORMAL,
				...sx,
			}}
			{...rest}
		>
			{children}
		</LoadingButton>
	);
};

export default DefaultButton;
