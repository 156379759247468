import {
	Box, Stack, Theme, useMediaQuery,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import { BodyText, TitleText } from 'common/presentation/components';
import { mobileimage } from 'assets/images/user-stories';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
	const { t } = useTranslation('translations');
	return (
		<Box sx={{
			ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
		}}
		>
			<Stack alignItems="start" direction={{ md: 'row', xs: 'column' }}>
				<Box>
					<TitleText sx={{
						fontStyle: 'italic',
						fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
					}}
					>
						{t('save_energy_with_iokee')}
					</TitleText>
					<TitleText sx={{
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
						fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
					}}
					>
						{t('your_iokee_app')}
					</TitleText>
					<BodyText size={Dimens.BODY_TEXT_M} sx={{ mt: 5 }}>
						{t('your_iokee_app_info')}
					</BodyText>
				</Box>
				<Box sx={{ mt: 5 }}>
					<img src={mobileimage} width={isMd ? '100%' : undefined} alt="Mobile Application" />
				</Box>
			</Stack>
		</Box>

	);
};

export default HeroSection;
