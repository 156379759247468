import { BodyText, TitleText } from 'common/presentation/components';
import { Grid, Stack, useTheme } from '@mui/material';
import React from 'react';
import {
	authenticationIcon, dataCollectionIcon, monitoringIcon, optimizationIcon,
	recognitionIcon, simulationIcon,
} from 'assets/icons';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';

const IokeeFeatures = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<Stack
			sx={{
				justifyContent: 'center',
				ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			direction="column"
			gap={6}
		>
			<Grid
				container
				spacing={2}
			>
				<Grid item xs={12} md={4}>
					<img src={dataCollectionIcon} alt="Data Collection Icon" />
					<TitleText sx={{ color: theme.palette.primary.main, fontSize: 18, mt: '30px' }}>

						{t('accurate_data_collection')}
					</TitleText>
					<BodyText sx={{ mt: '30px' }}>
						{t('accurate_data_collection_info')}
					</BodyText>
				</Grid>

				<Grid item xs={12} md={4}>
					<img src={simulationIcon} alt="Simulation Icon" />
					<TitleText sx={{ color: theme.palette.primary.main, fontSize: 18, mt: '30px' }}>
						{t('usage_insight')}
					</TitleText>
					<BodyText sx={{ mt: '30px' }}>
						{t('usage_insight_info')}
					</BodyText>
				</Grid>

				<Grid item xs={12} md={4}>
					<img src={monitoringIcon} alt="Monitoring Icon" />
					<TitleText sx={{ color: theme.palette.primary.main, fontSize: 18, mt: '30px' }}>
						{t('real_time_monitoring')}
					</TitleText>
					<BodyText sx={{ mt: '30px' }}>
						{t('real_time_monitoring_info')}
					</BodyText>
				</Grid>

			</Grid>

			<Grid
				container
				spacing={2}
			>

				<Grid item xs={12} md={4}>
					<img src={authenticationIcon} alt="Authentication Icon" />
					<TitleText sx={{ color: theme.palette.primary.main, fontSize: 18, mt: '30px' }}>
						{t('authentication_to_prevent')}
					</TitleText>
					<BodyText sx={{ mt: '30px' }}>
						{t('authentication_to_prevent_info')}
					</BodyText>
				</Grid>

				<Grid item xs={12} md={4}>
					<img src={recognitionIcon} alt="Recognition Icon" />
					<TitleText sx={{ color: theme.palette.primary.main, fontSize: 18, mt: '30px' }}>

						{t('energy_leaks_recognition')}
					</TitleText>
					<BodyText sx={{ mt: '30px' }}>
						{t('energy_leaks_recognition_info')}
					</BodyText>
				</Grid>

				<Grid item xs={12} md={4}>
					<img src={optimizationIcon} alt="Optimization Icon" />
					<TitleText sx={{ color: theme.palette.primary.main, fontSize: 18, mt: '30px' }}>
						{t('energy_optimization_recommendation')}
					</TitleText>
					<BodyText sx={{ mt: '30px' }}>
						{t('energy_optimization_recommendation_info')}
					</BodyText>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default IokeeFeatures;
