/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
	Box, IconButton, useTheme, Stack,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText, TitleText } from 'common/presentation/components';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
	iokeeMonoFront,
	iokeeMonoTopLeft,
	iokeeMonoTopRight,
	iokeeTriFront,
	iokeeTriTopLeft,
	iokeeTriTopRight,
} from 'assets/images/iokee-images';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

const EnergyEfficiencyController = () => {
	const theme = useTheme();
	const [images] = useState([
		iokeeMonoFront,
		iokeeMonoTopRight,
		iokeeMonoTopLeft,
		iokeeTriFront,
		iokeeTriTopRight,
		iokeeTriTopLeft,
	]);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const { t } = useTranslation(['translations']);

	const handleImageChange = () => {
		setSelectedIndex((prev) => {
			let mPrev = prev;
			if (prev === images.length - 1) {
				return 0;
			}
			mPrev += 1;
			return mPrev;
		});
	};

	useEffectCustom(() => {
		const interval = setInterval(() => {
			handleImageChange();
		}, 3000);
		return () => clearInterval(interval);
	}, []);
	const onImageClick = (index: number) => {
		setSelectedIndex(index);
	};

	return (
		<section>
			<Box
				sx={{
					pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
					pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
					mt: { md: Dimens.FOOTER_PADDING_VERTICAL, xs: Dimens.FOOTER_PADDING_VERTICAL_MOBILE },
				}}
			>

				<TitleText
					sx={{
						fontStyle: 'italic',
						fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
					}}
					dangerouslySetInnerHTML={{ __html: t('efficiency_controller_html').toUpperCase() }}
				/>

				<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between">
					<BodyText
						size={Dimens.BODY_TEXT_ML}
						flex={1}
						sx={{
							mt: 5,
							fontWeight: Dimens.FONT_WEIGHT_NORMAL,
						}}
					>
						{t('efficiency_controller_info')}
					</BodyText>

					<Stack
						flex={1}
						direction="row"
						justifyContent="center"
						spacing={4}
					>
						<img
							src={images[selectedIndex]}
							alt="Watch"
							style={{
								maxWidth: '400px',
								objectFit: 'scale-down',
							}}
							height="632px"
						/>
						<Stack justifyContent="center" sx={{ display: { md: 'flex', xs: 'none' } }}>
							<IconButton
								color="primary"
								sx={{ border: `1px solid ${theme.palette.primary.main}` }}
								onClick={() => handleImageChange()}
							>
								<ArrowForward />
							</IconButton>
						</Stack>
					</Stack>
				</Stack>

				<Stack
					direction="row"
					spacing={2}
					sx={{
						overflowX: 'scroll',
						'&::-webkit-scrollbar': { display: 'none' },
						scrollbarWidth: 'none',
						msOverflowStyle: 'none',
						zindex: 1,
					}}
				>
					{images.map((el, index) => (
						<img
							key={el.toString()}
							src={el}
							height="200px"
							width="200px"
							alt="Watch"
							onClick={() => onImageClick(index)}
							style={{
								cursor: 'pointer',
								objectFit: 'scale-down',
								borderRadius: '30px',
								border: selectedIndex === index ? `2px solid ${theme.palette.primary.main}` : 'none',
							}}
						/>
					))}
				</Stack>
			</Box>
		</section>
	);
};
export default EnergyEfficiencyController;
