/* eslint-disable class-methods-use-this */
import { meadowCommunity, solarPanelCommunity } from 'assets/images/user-stories';
import { CommentModel } from '../dto/comment-model';
import { CommunityApi } from './community-api';
import { ArticleModal } from '../dto/article-model';
import articlesEn from '../local/user-stories-en.json';
import articlesPt from '../local/user-stories-pt.json';
import { ICommunityReq } from '../../domain/entities/community';
import { ApiResponse } from 'common/data/entities/api-response';
import { reqPost } from 'common/data/remote/request';
import { Endpoints } from 'assets/Endpoints';

export class CommunityApiImpl implements CommunityApi {
	async getComments(): Promise<CommentModel[]> {
		try {
			const comments = [
				{
					id: 1,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 2,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 3,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 4,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 5,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 6,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 7,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 8,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 9,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 10,
					image: meadowCommunity,
					title: 'Solar Painels',
					comment: 'Now I pay zero bills for electricity and people ask for advice on power effienciency.',
					description: '',
					date: '12 may 2023',
					isHighlighted: false,
					type: {
						id: 1,
						title: 'Solar Painels',
					},

				},
				{
					id: 11,
					image: solarPanelCommunity,
					title: 'solar_panels',
					comment: 'comment',
					description: 'comment_description',
					date: '12 may 2023',
					isHighlighted: true,
					type: {
						id: 1,
						title: 'be_part_of_the_movement',
					},

				},
			];

			const productPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(comments);
				}, 300);
			});
			return productPromise.then(() => comments);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async getArticles(lang: string): Promise<ArticleModal[]> {
		try {
			const mArticles = lang === 'en' ? articlesEn : articlesPt;
			const articlePromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(mArticles);
				}, 300);
			});
			return articlePromise.then(() => mArticles);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async getArticleById(id: number, lang: string): Promise<ArticleModal> {
		try {
			const foundArt = lang === 'en' ? articlesEn : articlesPt;
			const article = foundArt.find((art) => art.id === id);
			const articlePromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(article);
				}, 300);
			});
			return articlePromise.then(() => article as ArticleModal);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	async joinCommunity(params: ICommunityReq): Promise<ApiResponse<null>> {
		try {
			const resp = await reqPost({
				url: Endpoints.JOIN_COMMUNITY,
				data: params,
			});
			const data = resp.data as ApiResponse<null>;
			return data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
