import React from 'react';
import CheckoutSuccessPage from './CheckoutSuccessPage';

const CheckoutSuccess = () => (
	<main>
		<CheckoutSuccessPage />
	</main>
);

export default CheckoutSuccess;
