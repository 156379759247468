import { Stack, Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import LoginForm from './LoginForm';
import LoginImageView from '../components/LoginImageView';
import { logoBlackMobile } from 'assets/icons';
import { SizedBox } from 'common/presentation/components';
import Dimens from 'assets/dimens';

const ClientLogin = () => {
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
	return (
		<main>
			<SizedBox height={3} />
			{!isMd && <img src={logoBlackMobile} style={{ marginLeft: Dimens.PAGE_PADDING_MOBILE }} alt="Logo" />}
			<SizedBox height={3} />
			<Stack direction={{ md: 'row', xs: 'column-reverse' }} columnGap={4}>
				<LoginForm />
				<LoginImageView />
			</Stack>
		</main>
	);
};

export default ClientLogin;
