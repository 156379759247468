/* eslint-disable no-console */
import {
	Stack,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import ProductCardItem from './components/ProductCardItem';
import { useTranslation } from 'react-i18next';
import { iokeeMonoTopLeft, iokeeTriTopLeft } from 'assets/images/iokee-images';

const EnergyEfficiencyControllerPhases = () => {
	const { t } = useTranslation('translations');

	const SINGLE_PHASE_PRICE = 199;
	const SINGLE_PHASE_PRICE_WITH_DISCOUNT = SINGLE_PHASE_PRICE * 0.75;
	const THREE_PHASE_PRICE = 299;
	const THREE_PHASE_PRICE_WITH_DISCOUNT = THREE_PHASE_PRICE * 0.75;

	return (
		<Stack
			sx={{
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				mb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				ml: { md: 0, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: 0, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
			direction={{ md: 'row', xs: 'column' }}
			justifyContent="center"
			alignItems="center"
			spacing={20}
			id="energy-efficiency-controller-phases"
		>
			<ProductCardItem
				price={SINGLE_PHASE_PRICE_WITH_DISCOUNT}
				originalPrice={SINGLE_PHASE_PRICE}
				storageFee={1}
				freeStorageTime={2}
				id={1}
				title={t('iokee_single_phase_analyser')}
				image={iokeeMonoTopLeft}
				description={t('iokee_single_phase_analyser_info')}
			/>
			<ProductCardItem
				price={THREE_PHASE_PRICE_WITH_DISCOUNT}
				originalPrice={THREE_PHASE_PRICE}
				storageFee={1.5}
				freeStorageTime={2}
				id={2}
				title={t('iokee_three_phase_analyser')}
				image={iokeeTriTopLeft}
				description={t('iokee_three_phase_analyser_info')}
			/>
		</Stack>

	);
};

export default EnergyEfficiencyControllerPhases;
