enum
Dimens{
	APP_BAR_PADDING_HORIZANTAL = '125px',
	APP_BAR_PADDING_HORIZANTAL_MOBILE = '33px',
	FOOTER_PADDING_VERTICAL = '80px',
	FOOTER_PADDING_VERTICAL_MOBILE = '30px',
	PAGE_PADDING = '125px',
	PAGE_PADDING_MOBILE = '20px',
	PAGE_PADDING_LARGE = '292px',
	TITLE_SPACING = '50px',
	TITLE_SPACING_MOBILE = '20px',
	SECTION_SPACING = '239px',
	SECTION_SPACING_MOBILE = '100px',
	CARD_PADDING_LARGE = '75px',
	CARD_PADDING_LARGE_MOBILE = '20px',
	APP_BAR_MARGIN_BOTTOM = '100px',
	APP_BAR_MARGIN_BOTTOM_MOBILE = '20px',

	TITLE_BODY_SPACING = '102px',
	TITLE_BODY_SPACING_MOBILE = '70px',

	CLIENT_AREA_PADDING_BOTTOM = '24px',

	VIDEO_BORDER_RADIUS = '35px',
	CARD_BORDER_RADIUS = '25px',

	BUTTON_PADDING_HORIZANTAL = '20px',
	BUTTON_PADDING_VERTICAL = '5px',

	CLIENT_AREA_PAGE_PADDING = '60px',
	CLIENT_AREA_PAGE_PADDING_MOBILE = '16px',
	CLIENT_AREA_PAGE_PADDING_VERTICAL = '60px',
	CLIENT_AREA_PAGE_PADDING_VERTICAL_MOBILE = '33px',
	CLIENT_AREA_PAGE_MARGIN_RIGHT = '55px',
	CLIENT_AREA_PAGE_BORDER_RADIUS = '50px',
	CLIENT_AREA_PAGE_BORDER_RADIUS_MOBILE = '12px',
	CLIENT_AREA_DEVICE_BUTTON_PADDING_HORIZANTAL = '40px',
	CLIENT_AREA_DEVICE_BUTTON_PADDING_VERTICAL = '25px',

	CLIENT_AREA_DEVICE_OPTION_CARD_WIDTH = '325px',
	CLIENT_AREA_DEVICE_OPTION_CARD_HEIGHT = '102px',
	CLIENT_AREA_DEVICE_OPTION_ICON_SIZE = '45px',

	CLIENT_AREA_USER_CARD_MINHEIGHT = '124px',
	CLIENT_AREA_USER_CARD_PADDING = '16px',
	CLIENT_AREA_USER_CARD_PADDING_MOBILE = '25px',
	CLIENT_AREA_USER_CARD_BORDER_RADIUS = '25px',
	CLIENT_AREA_USER_CARD_BORDER_RADIUS_MOBILE = '16px',

	CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL = '30px',
	CLIENT_AREA_DEVICE_CARD_PADDING_VERTICAL_MOBILE = '15px',
	CLIENT_AREA_DEVICE_CARD_PADDING_HORIZANTAL = '35px',
	CLIENT_AREA_DEVICE_CARD_PADDING_HORIZANTAL_MOBILE = '20px',

	CLIENT_AREA_USER_CARD_AVATAR_SIZE = '56px',

	CLIENT_BUTTON_VERTICAL = '20px',

	BACK_BUTTON_WIDTH = '150px',

	TEXT_DEFAULT = '16px',

	CART_ITEM_IMAGE_WIDTH = '100%',
	CART_ITEM_IMAGE_HEIGHT = '62px',

	// Text Sizes
	TEXT_SIZE_STEP = '158px',
	TEXT_SIZE_STEP_MOBILE = '80px',

	TILE_TEXT_XL_VIEW_PORT = '9vw',
	TITLE_TEXT_XL_MOBILE = '40px',
	TITLE_TEXT_L = '60px',
	TITLE_TEXT_L_MOBILE = '40px',
	TITEL_TEXT_M = '35px',
	TITLE_TEXT_M_MOBILE = '18px',
	TITLE_TEXT_S = '30px',

	BODY_TEXT_XL = '25px',
	BODY_TEXT_XL_MOBILE = '20px',
	BODY_TEXT_L = '20px',
	BODY_TEXT_L_MOBILE = '18px',
	BODY_TEXT_ML = '18px',
	BODY_TEXT_ML_MOBILE = '16px',
	BODY_TEXT_M = '20px',
	BODY_TEXT_M_MOBILE = '16px',
	BODY_TEXT_MS = '16px',
	BODY_TEXT_S = '14px',
	BUTTON_TEXT = '14px',
	BUTTON_TEXT_MOBILE = '12px',

	// ANIMATED CIRCLE RADIUS
	CIRCLE_RADIUS = '150px',
	TARIFF_PRICE_ITEM_AVATAR_SIZE = '65px',

	OPTION_LABEL_BORDER_RADIUS = '13px',
	OPTION_LABEL_PADDING = '15px',

	// Font weights
	FONT_WEIGHT_LIGHTER = '300',
	FONT_WEIGHT_LIGHT = '400',
	FONT_WEIGHT_NORMAL = '500',
	FONT_WEIGHT_BOLD = '600',
	FONT_WEIGHT_BOLDER = '700',
	FONT_WEIGHT_BOLDEST = '900',
	ABOUT_IOKEE_SECTION = '100%vh',
	SUBTITLE_TEXT = '25px',
	SUBTITLE_TEXT_MOBILE = '25px',
	PADDING_TOP_MISSION = '40px',
	PADDING_TOP_MISSION_MOBILE = '20px',
	// Section sizes
	COMMUNITY_IMAGE_HEIGHT = '500px',
	PRODUCT_CARD_HEIGHT = '700px',
	PRODUCT_CARD_HEIGHT_MOBILE = '470px',
	XS_SMALL_PADDING_PAIR='40px 25px',
	SM_SMALL_PADDING_PAIR= '50px 30px',
	MD_MEDIUM_PADDING_PAIR = '70px 37px',
	CHECKOUT_CARD_WIDTH = '100%',
	MD_SUMMARY_CARD_TITLE = '32px',
	MARGIN_BOTTOM_CARD_TITLE = '20px',
	MD_CHECKOUT_CARD_AREA= '62%',
	CART_PRODUCT_ADD_REMOVE_BUTTON_RADIOUS = '20px 20px 20px 20px',
	CHECKOUT_CART_BORDER_RADIOUS = '20px 20px 20px 20px',
	CHECKOUT_CARD_BORDER_LINE = '1px solid',

	MODEL_HEIGHT = '400px',
	MODEL_WIDTH = '400px',
	MODEL_HEIGHT_SINGLE_PHASE = '350px',
	MODEL_WIDTH_SINGLE_PHASE = '350px',
	MODEL_HEIGHT_MOBILE = '300px',
	MODEL_WIDTH_MOBILE = '300px',
	MODEL_HEIGHT_SINGLE_PHASE_MOBILE = '250px',
	MODEL_WIDTH_SINGLE_PHASE_MOBILE = '250px',
}

export default Dimens;
