import { Either, Left, Right } from '@ajtec/either-typescript';
import { IDeviceRepository } from '../../domain/repository/device-repository';
import { DeviceApiImpl } from '../remote/device-api-impl';
import { IDevice } from '../../domain/entities/device';

export class CDeviceRepositoryImpl implements IDeviceRepository {
	deviceApi = new DeviceApiImpl();

	async getUserProducts(): Promise<Either<string, IDevice[]>> {
		try {
			const products = await this.deviceApi.getUserProducts();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
