import { ApiResponse } from 'common/data/entities/api-response';
import { IOrderRequestParams } from '../../domain/entities/order';
import React, { createContext, useContext } from 'react';
import { COrderRepositoryImpl } from '../../data/repository/order-repository-impl';
import { CreateOrder } from '../../domain/usecases/create-order';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useCart from 'common/presentation/providers/cart.provider';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { useTranslation } from 'react-i18next';

type ContextProps = {
	loading: boolean
	error: string
	createOrder: (params: IOrderRequestParams) => void
	data: ApiResponse<string>
}

const OrderContext = createContext<ContextProps>({} as ContextProps);

export const OrderProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const { addDialog } = useFeedback();
	const [data, setData] = React.useState({} as ApiResponse<string>);
	const { t } = useTranslation('translations');

	const navigate = useNavigate();
	const { clearCart } = useCart();

	const orderRepository = new COrderRepositoryImpl();

	const createOrder = async (params: IOrderRequestParams) => {
		setLoading(true);
		const createOrderUseCase = new CreateOrder(orderRepository);
		const createOrderResult = await createOrderUseCase.call(params);
		if (createOrderResult.isRight()) {
			setData(createOrderResult.value);
			clearCart();
			navigate(AppLinks.CHECKOUT_SUCCESS);
		} else {
			setError(createOrderResult.error);
			addDialog({
				title: 'Error',
				message: createOrderResult.error || t('an_error_occurred'),
				error: true,
			});
		}
		setLoading(false);
	};

	const value = React.useMemo(() => ({
		loading,
		error,
		createOrder,
		data,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [data, error, loading]);

	return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};

export default function useOrder() {
	return useContext(OrderContext);
}
