import { TitleText } from 'common/presentation/components';
import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import { solarpanels } from 'assets/images/user-stories';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import InstallationSteps from './components/InstallationSteps';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

const Setup = () => {
	const { t } = useTranslation('translations');
	const [activeIndex, setActiveIndex] = useState(0);

	useEffectCustom(() => {
		const interval = setInterval(() => {
			setActiveIndex((prev) => {
				let mPrev = prev;
				if (mPrev !== 2) {
					mPrev += 1;
					return mPrev;
				}
				return 0;
			});
		}, 6000);
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<Box
				sx={{
					justifyContent: 'center',
					height: {
						md: Dimens.ABOUT_IOKEE_SECTION,
					},
					ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
					mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
					mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<TitleText sx={{
					fontSize: {
						md: Dimens.TITEL_TEXT_M,
						xs: Dimens.TITLE_TEXT_M_MOBILE,
					},
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
				}}
				>
					{t('installation').toUpperCase()}
				</TitleText>
				<TitleText sx={{
					fontSize: { md: Dimens.BODY_TEXT_XL },
					fontWeight: Dimens.FONT_WEIGHT_LIGHT,
				}}
				>
					{t('install_and_setup').toUpperCase()}
				</TitleText>
			</Box>
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				spacing={15}
				sx={{
					justifyContent: 'center',
					height: {
						md: Dimens.ABOUT_IOKEE_SECTION,
					},
					ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
					mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
					mt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE },
				}}
			>
				<Stack sx={{ width: { md: '50%', xs: '100%' } }} rowGap={5}>
					<InstallationSteps title={t('install_iokee_analiser')} description={t('install_iokee_analiser_info')} count={1} isHighlighted={activeIndex + 1 === 1} />
					<InstallationSteps title={t('download_the_iokee_app')} description={t('download_the_iokee_app_info')} count={2} isHighlighted={activeIndex + 1 === 2} />
					<InstallationSteps title={t('configure_your_device')} description={t('configure_your_device_info')} count={3} isHighlighted={activeIndex + 1 === 3} />
				</Stack>
				<Box sx={{ width: '50%', display: { md: 'block', xs: 'none' } }}>
					<img style={{ width: '100%' }} src={solarpanels} alt="solarpanel" />
				</Box>
			</Stack>
		</>
	);
};

export default Setup;
