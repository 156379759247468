import { Checked, UnChecked } from 'assets/icons';
import {
	Checkbox, Stack, SvgIcon, SxProps, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import Dimens from 'assets/dimens';
import BodyText from '../body-text/BodyText';

interface Props {
	primaryLabel: string;
	secondaryLabel: string;
	onClick: (value: boolean) => void;
	containerSx?: SxProps
}

const OptionCheckbox: React.FC<Props> = (props) => {
	const theme = useTheme();
	const {
		primaryLabel,
		secondaryLabel,
		onClick,
		containerSx,
	} = props;
	const [primaryChecked, setPrimaryChecked] = useState(true);
	const handleChange = () => {
		onClick.call(0, !primaryChecked);
		setPrimaryChecked(!primaryChecked);
	};
	return (
		<Stack direction="row" alignItems="flex-end" sx={containerSx}>
			<BodyText sx={{
				fontSize: Dimens.BODY_TEXT_ML,
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
				color: !primaryChecked ? theme.palette.textLight.main : 'inherit',
			}}
			>
				{primaryLabel}
			</BodyText>
			<Checkbox
				checked={primaryChecked}
				onChange={handleChange}
				icon={(
					<SvgIcon component="div">
						<UnChecked />
					</SvgIcon>
				)}
				checkedIcon={(
					<SvgIcon component="div">
						<Checked />
					</SvgIcon>
				)}
			/>
			<BodyText sx={{
				fontSize: Dimens.BODY_TEXT_ML,
				ml: 4,
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
				color: primaryChecked ? theme.palette.textLight.main : 'inherit',
			}}
			>
				{secondaryLabel}
			</BodyText>
			<Checkbox
				checked={!primaryChecked}
				onChange={handleChange}
				icon={(
					<SvgIcon component="div">
						<UnChecked />
					</SvgIcon>
				)}
				checkedIcon={(
					<SvgIcon component="div">
						<Checked />
					</SvgIcon>
				)}
			/>
		</Stack>
	);
};

export default OptionCheckbox;
