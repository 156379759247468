import Dimens from 'assets/dimens';
import { BodyText, TitleText } from 'common/presentation/components';
import {
	Box,
	Card, Stack, useTheme,
} from '@mui/material';
import React from 'react';

interface Props {
	title: string
	description: string
	image: string
	align?: 'row'
}

const AppInfoCard: React.FC<Props> = (props) => {
	const {
		title, description, image, align = 'column',
	} = props;
	const theme = useTheme();
	return (
		<Card
			sx={{
				width: '100%',
				bgcolor: theme.palette.bgLight.main,
				borderRadius: Dimens.VIDEO_BORDER_RADIUS,
				maxHeight: { md: '75vh', xs: '100%' },
			}}
		>
			<Stack
				direction={{ md: align, xs: 'column' }}
				sx={{
					pt: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE },
					pl: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE },
					pr: { md: Dimens.TITLE_SPACING, xs: Dimens.TITLE_SPACING_MOBILE },
				}}
			>
				<Box>
					<TitleText
						sx={{
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },

						}}
					>
						{title}
					</TitleText>
					<BodyText
						size={Dimens.BODY_TEXT_M}
						sx={{ mt: { md: 4, xs: 2 }, fontWeight: Dimens.FONT_WEIGHT_LIGHTER }}
					>
						{description}
					</BodyText>
				</Box>
				<img src={image} alt="Mobile Application" />
			</Stack>

		</Card>
	);
};

export default AppInfoCard;
