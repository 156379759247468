import React from 'react';
import { BodyText, TitleText } from 'common/presentation/components';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import DefaultButton from 'common/presentation/components/default-button/DefaultButton';

const GreenManifesto = () => {
	const { t } = useTranslation('translations');

	return (
		<Box
			sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			}}
		>
			<Box sx={{ mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE } }}>
				<TitleText
					size={Dimens.TITLE_TEXT_L}
					sx={{
						fontStyle: 'italic',
						fontWeight: Dimens.FONT_WEIGHT_BOLDEST,
					}}
				>
					{t('green_manifesto').toUpperCase()}
				</TitleText>
			</Box>
			<Stack
				alignItems="flex-end"
				sx={{
					pt: {
						md: Dimens.TITLE_BODY_SPACING,
						xs: Dimens.TITLE_BODY_SPACING_MOBILE,
					},
				}}
			>
				<BodyText
					size={Dimens.BODY_TEXT_L}
					sx={{
						fontWeight: Dimens.FONT_WEIGHT_NORMAL,
						ml: { md: Dimens.PAGE_PADDING_LARGE, xs: 0 },
						width: { md: '60%', xs: '100%' },
					}}
				>
					{t('green_manifesto_info')}
				</BodyText>
				<Box sx={{ mt: 5 }}>
					<DefaultButton>{t('read_all')}</DefaultButton>
				</Box>
			</Stack>
		</Box>
	);
};

export default GreenManifesto;
