import { BodyText, SizedBox } from 'common/presentation/components';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import TechSpecListItem from './TechSpecListItem';

const MonophaseHidden = () => {
	const { t } = useTranslation('translations');
	return (
		<Stack alignItems="center" sx={{ mt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE } }}>
			<Box>
				<BodyText color="primary.main" sx={{ fontSize: Dimens.BODY_TEXT_MS }}>
					{t('electrical').toUpperCase()}
				</BodyText>
				<SizedBox height={2} />
				<TechSpecListItem title={t('power_supply_ac')} description="110V – 240V" />
				<TechSpecListItem title={t('power_consumption')} description="<3W" />
				<TechSpecListItem title={t('temparature_range')} description={t('temparature_range_info')} />
				<TechSpecListItem title={t('humidity')} description={t('temparature_range_info')} />
				<TechSpecListItem title={t('max_altitude')} description={t('temparature_range_info')} />
				<TechSpecListItem title={t('mounting')} description={t('mounting_info')} />
				<SizedBox height={3} />
				<BodyText color="primary.main" sx={{ fontSize: Dimens.BODY_TEXT_MS }}>
					{t('sensors').toUpperCase()}
				</BodyText>
				<SizedBox height={2} />
				<TechSpecListItem title={t('current_range')} />
				<TechSpecListItem title={t('general_and_partial_metering')} />
				<TechSpecListItem title={t('voltmeter_ammeter_power_and_energy_meter')} />
				<SizedBox height={3} />
				<BodyText color="primary.main" sx={{ fontSize: Dimens.BODY_TEXT_MS }}>
					{t('output').toUpperCase()}
				</BodyText>
				<SizedBox height={2} />
				<TechSpecListItem title={t('two_relay_on_off')} />
				<TechSpecListItem title={t('operating_regime')} description="250VAC / 2Amps max. (resistive)" />
				<TechSpecListItem title={t('modes')} description={t('modes_info')} />
				<SizedBox height={3} />
				<BodyText color="primary.main" sx={{ fontSize: Dimens.BODY_TEXT_MS }}>
					{t('communication').toUpperCase()}
				</BodyText>
				<SizedBox height={2} />
				<TechSpecListItem title={t('wifi')} description="2400 – 2495MHz" />
				<TechSpecListItem title={t('max_rf_power')} description="<20dBm" />
				<TechSpecListItem title={t('wifi_protocol')} description="802.11b/g/n" />
				<TechSpecListItem title={t('wifi_range')} description={t('wifi_ranhe_info')} />
			</Box>
		</Stack>
	);
};

export default MonophaseHidden;
