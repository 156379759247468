import { Either, Left, Right } from '@ajtec/either-typescript';
import { IDeviceDetailRepository } from '../../domain/repository/device-detail-repository';
import { DeviceDetailApiImpl } from '../remote/device-detail-api-impl';
import { EnergyConsumptionParams, IEnergyConsumption } from '../../domain/entities/energy-consuption';

export class CDeviceDetailRepositoryImpl implements IDeviceDetailRepository {
	deviceApi = new DeviceDetailApiImpl();

	async getEnergyConsumption(
		params: EnergyConsumptionParams,
	): Promise<Either<string, IEnergyConsumption>> {
		try {
			const products = await this.deviceApi.getEnergyConsumption(params);
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
