import { ApiResponse } from 'common/data/entities/api-response';
import { ICoorporate, ICoorporateReq, ICoorporateResError } from '../../domain/entities/coorporate';
import React, { createContext, useContext } from 'react';
import { CContactRepositoryImpl } from '../../data/repository/contact-repository-impl';
import { CreateContact } from '../../domain/usecases/create-contact';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { useTranslation } from 'react-i18next';

type ContextProps = {
	loading: boolean
	error: string
	createContact: (params: ICoorporate) => void
	data: ApiResponse<string>
	formData: ICoorporateReq
	formError: ICoorporateResError
	setFormData: (params: ICoorporateReq) => void
	setFormError: (params: ICoorporateResError) => void
}

const ContactContext = createContext<ContextProps>({} as ContextProps);

export const ContactProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [data, setData] = React.useState({} as ApiResponse<string>);
	const { t } = useTranslation('translations');
	const [formData, setFormData] = React.useState({
		name: '',
		email: '',
		message: t('default_contact_message_beta_tester'),
		phone: '',
		privacyPolicy: false,
	});
	const [formError, setFormError] = React.useState({
		name: '',
		email: '',
		message: '',
		phone: '',
		privacyPolicy: '',
	});
	const { addDialog } = useFeedback();
	const contactRepository = new CContactRepositoryImpl();

	const createContact = async (params: ICoorporate) => {
		setLoading(true);
		const createContactUseCase = new CreateContact(contactRepository);
		const createContactResult = await createContactUseCase.call(params);
		if (createContactResult.isRight()) {
			setData(createContactResult.value);
			addDialog({
				title: 'Sucesso',
				message: 'Sua mensagem foi enviada com sucesso, em breve entraremos em contato.',
				error: false,
			});
			setFormData({
				...formData,
				name: '',
				email: '',
				phone: '',
				privacyPolicy: false,
			});
		} else {
			setError(createContactResult.error);
			addDialog({
				title: 'Erro',
				message: 'Ocorreu um erro ao enviar sua mensagem, tente novamente.',
				error: true,
			});
		}
		setLoading(false);
	};

	const value = React.useMemo(() => ({
		loading,
		error,
		createContact,
		data,
		formData,
		formError,
		setFormData,
		setFormError,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [data, error, loading, formData, formError]);

	return <ContactContext.Provider value={value}>{children}</ContactContext.Provider>;
};

export default function useContact() {
	return useContext(ContactContext);
}
