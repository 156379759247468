import React from 'react';
import ContactForm from './ContactForm';
import { ContactProvider } from './provider/contact-provider';

const ContactUs = () => (
	<main>
		<ContactProvider>
			<ContactForm />
		</ContactProvider>
	</main>
);

export default ContactUs;
