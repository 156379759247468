import React, {
	FC, createContext, useContext, useMemo, useState,
} from 'react';
import { IDevice } from '../../domain/entities/device';
import { CDeviceRepositoryImpl } from '../../data/repository/device-repository-impl';
import { GetUserProducts } from '../../domain/usecases/get-user-products';

interface DeviceContextProps {
   devices?: IDevice[];
   loading: boolean;
   getUserProducts: () => void;
   error: string
}

const DeviceContext = createContext({} as DeviceContextProps);

export const DeviceProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [devices, setDevices] = useState<IDevice[]>([] as IDevice[]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const deviceRepository = new CDeviceRepositoryImpl();

	const getUserProducts = async () => {
		setLoading(true);
		const deviceUseCase = new GetUserProducts(deviceRepository);
		const deviceResult = await deviceUseCase.call();
		if (deviceResult.isRight()) {
			setDevices(deviceResult.value);
		} else {
			setError(deviceResult.error);
		}
		setLoading(false);
	};

	const value = useMemo(() => ({
		devices,
		loading,
		getUserProducts,
		error,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [devices,
		loading,
		error]);
	return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
};

export default function useDevices() {
	return useContext(DeviceContext);
}
