/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Box,
	Card,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Slide,
	ListItemProps,
	ListItemButton,
	useTheme,
	Stack,
	SvgIcon,
	Badge,
} from '@mui/material';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Clear } from '@mui/icons-material';
import {
	Link,
	Link as RouterLink, useLocation, useNavigate,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SizedBox from '../sized-box/SizedBox';
import AppLinks from 'assets/applinks.routes';
import {
	Cart,
	Facebook, Instagram, LinkedIn, logoMobile,
} from 'assets/icons';
import DefaultButton from '../default-button/DefaultButton';
import BodyText from '../body-text/BodyText';
import Dimens from 'assets/dimens';
import useCart from '../../providers/cart.provider';

const Transition = React.forwardRef((
	props: TransitionProps & {
    children: React.ReactElement;
  },
	ref: React.Ref<unknown>,
) => <Slide direction="down" ref={ref} {...props} />);

interface Props {
	open: boolean;
	handleClose: () => void
}

interface ListItemLinkProps extends ListItemProps {
  to: string;
  icon?: React.ReactNode;
  title: string
}

const ListItemLink = (props: ListItemLinkProps) => {
	const location = useLocation();
	const [pathname, setPathName] = React.useState(location.pathname);
	const { palette } = useTheme();

	React.useEffect(() => {
		setPathName(location.pathname);
	}, [location]);

	const {
		to, icon, title, ...other
	} = props;

	return (
		<ListItemButton component={RouterLink as any} to={to} {...other} sx={{ ml: 0, pl: 0 }}>
			<BodyText
				sx={{
					color: palette.primary.main,
					fontWeight: pathname === to ? Dimens.FONT_WEIGHT_BOLDER : Dimens.FONT_WEIGHT_NORMAL,
					fontSize: Dimens.BODY_TEXT_MS,
					mr: 2,
				}}

			>
				{title}
			</BodyText>
			{icon}
		</ListItemButton>
	);
};

const NavMenuMobile: React.FC<Props> = (props) => {
	const { open, handleClose } = props;
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { cartProducts } = useCart();

	const handleClientAreaClick = () => {
		handleClose();
		navigate(AppLinks.CLIENT_AREA_LOGIN);
	};

	return (
		<Dialog fullScreen TransitionComponent={Transition} open={open} onClose={handleClose}>
			<DialogTitle boxShadow={2}>
				<Box
					sx={{
						display: 'flex',
						flex: 1,
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box sx={{ mr: 1 }}>
						<Link to={AppLinks.LANDING} style={{ textDecoration: 'none' }}>
							<img src={logoMobile} alt="logo" width="50px" />
						</Link>
					</Box>
					<IconButton color="primary" onClick={handleClose}>
						<Clear />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Card elevation={3} sx={{ p: 2, mt: 3 }}>
					<DefaultButton variant="contained" onClick={handleClientAreaClick}>{t('client_area')}</DefaultButton>
					<SizedBox height={4} />
					<ListItemLink onClick={handleClose} to={AppLinks.PRODUCT} title={t('products')} />
					<ListItemLink onClick={handleClose} to={AppLinks.MOBILE_APP} title={t('mobile_app')} />
					<ListItemLink onClick={handleClose} to={AppLinks.COMMUNITY} title={t('community')} />
					<ListItemLink onClick={handleClose} to={AppLinks.CONTACT_US} title={t('contact_us')} />
					<ListItemLink onClick={handleClose} to={AppLinks.CHECKOUT_PAGE} title={t('shopping_cart')} icon={<Badge badgeContent={cartProducts.length} color="primary"><SvgIcon><Cart /></SvgIcon></Badge>} />
				</Card>

				<SizedBox height={4} />
				<BodyText
					sx={{
						fontSize: Dimens.BODY_TEXT_MS,
						color: 'primary.main',
						textAlign: 'center',
						mb: 2,
					}}
				>
					{t('follow_us').toUpperCase()}
				</BodyText>
				<Stack direction="row" justifyContent="center">
					<SvgIcon component="div">
						<LinkedIn />
					</SvgIcon>
					<SvgIcon component="div" sx={{ ml: 3 }}>
						<Instagram />
					</SvgIcon>
					<SvgIcon component="div" sx={{ ml: 3 }}>
						<Facebook />
					</SvgIcon>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default NavMenuMobile;
