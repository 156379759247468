import React from 'react';
import CardsSection from './CardsSection';
import HeroSection from './HeroSection';
import { CommunityForm } from 'common/presentation/components';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';

const MobileAppPage = () => (
	<main>
		<CommunityProvier>
			<HeroSection />
			<CardsSection />
			<CommunityForm />
		</CommunityProvier>
	</main>

);

export default MobileAppPage;
