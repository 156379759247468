import { Period } from 'common/domain/entities/period';

export interface IEnergyConsumption {
	monthlyExpectation: number;
	billingStart: number;
	previousPeriod: Period;
	previousConsumption: number;
	previousAmount: number;
	currentPeriod: Period;
	currentConsumption: number;
	currentAmount: number;
	consumptionProjection: number;
	amountProjection: number;
}

export interface EnergyConsumptionParams {
	userProductId: number;
	sensorTypeId: number
}

export const DEVICE_SINGLE_PHASE = 1;

export const DROPDOWN_OPTIONS = [{ id: 1, name: 'Geral' }, { id: 2, name: 'Parcial' }];
