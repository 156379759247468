/* eslint-disable class-methods-use-this */
import { reqGet } from 'common/data/remote/request';
import { Endpoints } from 'assets/Endpoints';
import { TariffApi } from './tariff-api';
import { TariffDatasetModel } from '../dto/device-model';

export class TariffApiImpl implements TariffApi {
	async getTariffDataset(userProductId: number): Promise<TariffDatasetModel> {
		try {
			const resp = await reqGet({
				url: Endpoints.TARIFF_DATASET.format(userProductId),
			});
			const data = resp.data.data.present as TariffDatasetModel;
			return data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
