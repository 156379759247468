import Dimens from 'assets/dimens';
import { Box, Stack, useTheme } from '@mui/material';
import React from 'react';
import BodyText from '../body-text/BodyText';

interface Props {
	count: number
	increment: () => void
	decrement: () => void
}

const CounterButton: React.FC<Props> = (props) => {
	const theme = useTheme();
	const { count, increment, decrement } = props;
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				border: Dimens.CHECKOUT_CARD_BORDER_LINE,
				borderColor: theme.palette.primary.main,
				borderRadius: 100,
				alignItems: 'center',
				padding: '2px',
				width: '100px',
				height: '34px',
			}}
			spacing={1}
		>
			<Box
				onClick={decrement}
				sx={{
					bgcolor: theme.palette.primary.main,
					width: '30px',
					height: '30px',
					borderRadius: 30,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer',

				}}
				component="span"
			>
				<BodyText sx={{ color: theme.palette.background.default, fontSize: Dimens.BODY_TEXT_L }}>
					-
				</BodyText>
			</Box>
			<BodyText>{count}</BodyText>
			<Box
				onClick={increment}
				sx={{
					bgcolor: theme.palette.primary.main,
					width: '30px',
					height: '30px',
					borderRadius: 30,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer',
				}}
				component="span"
			>
				<BodyText sx={{ color: theme.palette.background.default, fontSize: Dimens.BODY_TEXT_L }}>
					+
				</BodyText>
			</Box>
		</Stack>
	);
};

export default CounterButton;
