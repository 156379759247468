import Dimens from 'assets/dimens';
import {
	Box, CircularProgress, Stack, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import ClientBackButton from 'common/presentation/components/client-back-button/ClientBackButton';
import { useNavigate } from 'react-router-dom';
import { BodyText, OptionCheckbox } from 'common/presentation/components';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { IDevice } from 'features/client-device-list/domain/entities/device';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import TarifPlanItem from './components/TarifPlanItem';
import TariffPriceItem from './components/TariffPriceItem';
import useTariff from './provider/tariff-provider';
import { useTranslation } from 'react-i18next';

const Tariff = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const device = LocalStorageWrapper.get(LocalStorageKeys.DEVICE) as IDevice;

	const {
		getTariffDataset, tariffDataset, loading, error,
	} = useTariff();

	const [planType, setPlanType] = useState(true);

	useEffectCustom(() => {
		getTariffDataset(device.userProductID);
	}, []);

	const handleGoBack = () => {
		navigate(-1);
	};

	const handlePlanTypeChange = (value: boolean) => {
		setPlanType(value);
	};

	return (
		<Box>
			<ClientBackButton onBackClick={handleGoBack} />
			<BodyText sx={{ fontSize: Dimens.BODY_TEXT_XL, fontWeight: Dimens.FONT_WEIGHT_BOLD, mt: 3 }}>
				{device.name}
				{' '}
				-
				{' '}
				{t('my_tariff')}
			</BodyText>
			<OptionCheckbox
				primaryLabel={t('plan')}
				secondaryLabel={t('price')}
				onClick={handlePlanTypeChange}
				containerSx={{ mt: 5 }}
			/>
			{loading && (
				<Stack alignItems="center">
					<CircularProgress />
				</Stack>
			)}
			{error && <BodyText sx={{ color: theme.palette.error.main }}>{error}</BodyText>}
			{tariffDataset && (
				<>
					{planType && <TarifPlanItem tariffDataSet={tariffDataset} />}
					{!planType && (
						<TariffPriceItem
							tariffDataSet={tariffDataset}
							r2Enabled={device?.plan?.planTypeID === 2}
							r3Enabled={device?.plan?.planTypeID === 3}
						/>
					)}
				</>
			)}
		</Box>
	);
};

export default Tariff;
