import { BodyText } from 'common/presentation/components';
import { Stack, useTheme } from '@mui/material';
import React, { FC } from 'react';
import Dimens from 'assets/dimens';

interface Props {
	title: string;
	value: string
}

const ListItem:FC<Props> = (props) => {
	const { title, value } = props;
	const theme = useTheme();
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			sx={{
				pb: 2.5, pt: 2.5, borderBottomColor: theme.palette.background.default, borderBottomStyle: 'solid', borderBottomWidth: '2px',
			}}
		>
			<BodyText sx={{
				fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE },
			}}
			>
				{title}
			</BodyText>
			<BodyText sx={{
				fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE },
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
			}}
			>
				{value}
			</BodyText>
		</Stack>
	);
};

export default ListItem;
