/* eslint-disable class-methods-use-this */
import { ApiResponse } from 'common/data/entities/api-response';
import { reqGet } from 'common/data/remote/request';
import { Endpoints } from 'assets/Endpoints';
import { DeviceApi } from './device-api';
import { DeviceModel } from '../dto/device-model';

export class DeviceApiImpl implements DeviceApi {
	async getUserProducts(): Promise<DeviceModel[]> {
		try {
			const resp = await reqGet({
				url: Endpoints.USER_PRODUCTS,
			});
			const data = resp.data as ApiResponse<DeviceModel[]>;
			return data.data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
