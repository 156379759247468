import React from 'react';
import DeviceListPage from './DeviceListPage';
import { DeviceProvider } from './provider/device.provider';

const DeviceList = () => (
	<DeviceProvider>
		<DeviceListPage />
	</DeviceProvider>
);

export default DeviceList;
