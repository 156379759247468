import { Typography, TypographyProps, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

interface Props extends TypographyProps {
	maxLines?: number;
	size?: Dimens.BODY_TEXT_L
	| Dimens.BODY_TEXT_S
	| Dimens.SUBTITLE_TEXT
	| Dimens.SUBTITLE_TEXT_MOBILE
	| Dimens.BODY_TEXT_M
	| Dimens.BODY_TEXT_XL
	| Dimens.BODY_TEXT_ML
	color?: string
}

const BodyText: React.FC<Props> = (props) => {
	const {
		children, sx, color, maxLines = 100, size, ...remainingProps
	} = props;
	const theme = useTheme();
	return (
		<Typography
			{...remainingProps}
			sx={{
				fontSize: { md: size },
				fontWeight: Dimens.FONT_WEIGHT_NORMAL,
				fontStyle: 'normal',
				color: color ?? theme.palette.textMain.main,
				overflow: 'hidden',
				display: '-webkit-box',
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: maxLines,
				whiteSpace: 'pre-wrap',
				...sx,
			}}
		>
			{children}
		</Typography>
	);
};

export default BodyText;
