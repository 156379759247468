import React, {
	createContext, useContext, useMemo, useState,
} from 'react';
import { Comment } from '../../domain/entities/comment';
import { CCommunityRepositoryImpl } from '../../data/repositories/community-repository-impl';
import { GetComments } from '../../domain/usecases/get-comments';
import { Article } from '../../domain/entities/article';
import { GetArticles } from '../../domain/usecases/get-articles';
import { GetArticleById } from '../../domain/usecases/get-article-by-id';
import { ICommunityError, ICommunityReq } from '../../domain/entities/community';
import { JoinCommunity } from '../../domain/usecases/join-community';
import useFeedback from 'common/presentation/providers/feedback.provider';
import { useTranslation } from 'react-i18next';

type CommunityProps = {
	comments: Comment[];
	loading: boolean;
	error: string;
	highlightedComment: Comment | undefined;
	getComments: () => Promise<void>
	articles: Article[],
	getArticles: (lang: string) => Promise<void>
	loadingArticles: boolean;
	getArticleById: (id: number, lang: string) => Promise<void>;
	article: Article | null;
	articleByIdLoading: boolean;
	joinCommunityLoading: boolean;
	joinCommunity: (params: ICommunityReq) => Promise<void>;
	joinCommunityData: ICommunityReq;
	joinCommunityErrors: ICommunityError;
	setJoinCommunityData: (data: ICommunityReq) => void;
	setJoinCommunityErrors: (data: ICommunityError) => void;

}

const ComminityContext = createContext({} as CommunityProps);

export const CommunityProvier: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [comments, setComments] = useState([] as Comment[]);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [highlightedComment, setHighlightedComment] = useState<Comment | undefined>();
	const [articles, setArticles] = useState([] as Article[]);
	const [loadingArticles, setLoadingArticles] = useState(false);
	const [article, setArticle] = useState<Article | null>(null);
	const [articleByIdLoading, setArticleByIdLoading] = useState(false);
	const [joinCommunityLoading, setJoinCommunityLoading] = useState(false);
	const [joinCommunityData, setJoinCommunityData] = useState<ICommunityReq>({
		name: '',
		email: '',
		country: '',
		privacyPolicy: false,
	});
	const [joinCommunityErrors, setJoinCommunityErrors] = useState<ICommunityError>({
		name: '',
		email: '',
		country: '',
		privacyPolicy: '',
	});

	const { addToast, addDialog } = useFeedback();
	const { t } = useTranslation('translations');
	const communityRepository = new CCommunityRepositoryImpl();

	const getComments = async () => {
		setLoading(true);
		const productUseCase = new GetComments(communityRepository);
		const productResult = await productUseCase.call();
		if (productResult.isRight()) {
			const highlighted = productResult.value.find((el) => el.isHighlighted);
			setHighlightedComment(highlighted);
			setComments(productResult.value);
		} else {
			setError(productResult.error);
		}
		setLoading(false);
	};
	const getArticles = async (lang: string) => {
		setLoadingArticles(true);
		const getArticleUseCase = new GetArticles(communityRepository);
		const result = await getArticleUseCase.call(lang);
		if (result.isRight()) {
			setArticles(result.value);
		} else {
			setError(result.error);
		}
		setLoadingArticles(false);
	};

	const getArticleById = async (id: number, lang: string) => {
		setArticleByIdLoading(true);
		const getArticleUseCase = new GetArticleById(communityRepository);
		const result = await getArticleUseCase.call(id, lang);
		if (result.isRight()) {
			setArticle(result.value);
		} else {
			setError(result.error);
		}
		setArticleByIdLoading(false);
	};

	const joinCommunity = async (params: ICommunityReq) => {
		setJoinCommunityLoading(true);
		const joinCommunityUseCase = new JoinCommunity(communityRepository);
		const result = await joinCommunityUseCase.call(params);
		if (result.isRight()) {
			addToast({
				title: t('success'),
				message: result.value.message,
				error: false,
			});
			setJoinCommunityData({
				name: '',
				email: '',
				country: '',
				privacyPolicy: false,
			});
		} else {
			addDialog({
				title: t('error'),
				message: result.error,
				error: true,
			});
		}
		setJoinCommunityLoading(false);
	};

	const value = useMemo(() => ({
		comments,
		error,
		loading,
		getComments,
		highlightedComment,
		articles,
		getArticles,
		loadingArticles,
		getArticleById,
		article,
		articleByIdLoading,
		joinCommunityLoading,
		joinCommunity,
		joinCommunityData,
		setJoinCommunityData,
		joinCommunityErrors,
		setJoinCommunityErrors,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [comments,
		error,
		loading,
		highlightedComment,
		articles,
		loadingArticles,
		article,
		articleByIdLoading,
		joinCommunityLoading,
		joinCommunityData,
		joinCommunityErrors,
	]);

	return (
		<ComminityContext.Provider
			value={value}
		>
			{children}
		</ComminityContext.Provider>
	);
};

export default function useCommunity() {
	return useContext(ComminityContext);
}
