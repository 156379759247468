import {
	Box, Grid,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import { mobileimage1, mobileimage2, mobileimage3 } from 'assets/images/user-stories';
import AppInfoCard from './components/AppInfoCard';

const CardsSection = () => (
	<Box sx={{
		ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
		mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
		mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
	}}
	>
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<AppInfoCard title="Personalize a sua expectativa de pagamento mensal" description="Tenha total controle sobre os seus consumos energéticos com gráficos atualizados em tempo real, dados de faturação e consumo. Com a funcionalidade de expectativa mensal poderá definir o que pretende pagar de eletricidade mensalmente." image={mobileimage1} />
			</Grid>
			<Grid item xs={12} md={6}>
				<AppInfoCard title="Proteja os seus equipamentos, não deixe o seu quadro disparar" description="No Iokee pode definir o valor máximo que o seu quadro pode atingir, defina estes alertas para que possa viver em maior segurança sem danificar nenhum equipamento." image={mobileimage2} />
			</Grid>
		</Grid>
		<Grid container spacing={2} sx={{ mt: 2 }}>
			<Grid item xs={12} md={12}>
				<AppInfoCard align="row" title="Esteja sempre atualizado, faça escolhas ponderadas e com base em dados reais" description="Na sua aplicação Iokee poderá encontrar toda a informação que necessita em tempo real, saiba qual a sua tarifa ideal e personalizada aos seus consumos energéticos, esteja atento aos alertas, aplicação em constante atualização para que tenha a melhor experiência de utilizador." image={mobileimage3} />
			</Grid>
		</Grid>
	</Box>
);

export default CardsSection;
