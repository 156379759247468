import {
	BodyText, CounterButton, DefaultButton, TitleText,
} from 'common/presentation/components';
import { Box, Divider, Stack } from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import useCart from 'common/presentation/providers/cart.provider';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/price.utils';

interface Props {
	id: number
	price: number
	originalPrice: number
	freeStorageTime: number
	storageFee: number
	title: string
	image: string
	description: string
}

const ProductCardItem: React.FC<Props> = (props) => {
	const {
		id, title, description, image, price, originalPrice, storageFee, freeStorageTime,
	} = props;

	const { addToCart } = useCart();
	const { t } = useTranslation('translations');

	const [count, setCount] = React.useState(1);

	const handleIncrement = () => {
		setCount((prevCount) => prevCount + 1);
	};

	const handleDecrement = () => {
		setCount((prevCount) => {
			if (prevCount === 1) return prevCount;
			return prevCount - 1;
		});
	};

	const handleAddToCartClick = () => {
		addToCart({
			productId: id,
			name: title,
			price,
			originalPrice,
			storageFee,
			freeStorageTime,
			image,
			description: '',
			qty: count,
		});
	};

	return (
		<Box width={{ md: '35%', xs: '100%' }}>
			<img src={image} width="100%" alt="Arrow icon" />
			<TitleText
				fontSize={Dimens.TITLE_TEXT_S}
				sx={{
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
					mt: 3,
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
				}}
			>
				{title}
			</TitleText>
			<BodyText sx={{ mt: 2 }}>
				{description}
			</BodyText>
			<Divider sx={{ mt: 3, mb: 3 }} />
			<Stack direction="column" justifyContent="center" alignItems="flex-end">
				<BodyText sx={{ fontSize: Dimens.BODY_TEXT_M, fontWeight: Dimens.FONT_WEIGHT_BOLD }}>
					{originalPrice && <s>{formatPrice(originalPrice)}</s>}
					{'  '}
					{formatPrice(price)}
					{' '}
					{t('vat_included')}
				</BodyText>
				<BodyText sx={{ fontSize: Dimens.BUTTON_TEXT_MOBILE, fontWeight: Dimens.FONT_WEIGHT_LIGHTER }}>{t('iokee_energy_analyzer_storage').format(storageFee, freeStorageTime)}</BodyText>
			</Stack>
			<Stack direction="row" justifyContent="flex-end" alignItems="center">
				{/* <DefaultButton sx={{ p: 0, m: 0 }} onClick={onClick} variant="text"
				endIcon={<ArrowLeft />}>{t('whats_in_iokee_box')}</DefaultButton> */}
				<BodyText>{t('cables_and_adaptors')}</BodyText>
			</Stack>
			<Stack direction="row" justifyContent="space-around" sx={{ mt: 5 }}>
				<CounterButton count={count} increment={handleIncrement} decrement={handleDecrement} />
				<DefaultButton onClick={handleAddToCartClick}>
					{t('add_to_shopping_bag')}
				</DefaultButton>
			</Stack>
		</Box>
	);
};

export default ProductCardItem;
