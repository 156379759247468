import { TitleText } from 'common/presentation/components';
import {
	Collapse, Stack, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { arrowIcon, arrowIcon2 } from 'assets/icons';
import Dimens from 'assets/dimens';

interface Props {
	title: string;
	hiddenItem: React.ReactNode;
}

const TechnicalSpecsItem: React.FC<Props> = (props) => {
	const { title, hiddenItem } = props;
	const [open, setOpen] = useState(false);
	const theme = useTheme();

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" onClick={handleClick} sx={{ cursor: 'pointer' }}>
				<TitleText
					size={Dimens.TITEL_TEXT_M}
					sx={{ color: theme.palette.primary.main }}
				>
					{title}
				</TitleText>
				{open ? <img src={arrowIcon} alt="Arrow icon" width="35px" /> : <img src={arrowIcon2} alt="Arrow icon" width="35px" />}
			</Stack>
			<Collapse in={open}>
				{hiddenItem}
			</Collapse>
		</>
	);
};

export default TechnicalSpecsItem;
