import { Either } from '@ajtec/either-typescript';
import { ICoorporate } from '../entities/coorporate';
import { IContactRepository } from '../repository/contact-repository';
import { ApiResponse } from 'common/data/entities/api-response';

export class CreateContact {
	repository: IContactRepository;

	constructor(contactRepository: IContactRepository) {
		this.repository = contactRepository;
	}

	call(params: ICoorporate): Promise<Either<string, ApiResponse<string>>> {
		return this.repository.createContact(params);
	}
}
