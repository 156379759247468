import { Either } from '@ajtec/either-typescript';
import { IUser } from '../entities/user';
import { ILoginRepository } from '../repository/login-repository';

export class Login {
	repository: ILoginRepository;

	constructor(loginRepository: ILoginRepository) {
		this.repository = loginRepository;
	}

	call(params: LoginParams): Promise<Either<string, IUser>> {
		return this.repository.login(params);
	}
}

export interface LoginParams {
	email: string;
	password: string
}
