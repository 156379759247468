import React, {
	FC, createContext, useContext, useMemo, useState,
} from 'react';
import { ITariffDataset } from '../../domain/entities/tariff-dataset';
import { CTariffRepositoryImpl } from '../../data/repository/device-repository-impl';
import { GetTariffDataSet } from '../../domain/usecases/get-tariff-dataset';

interface TariffContextProps {
   tariffDataset?: ITariffDataset | null;
   loading: boolean;
   getTariffDataset: (userProductId: number) => void;
   error: string
}

const TariffContext = createContext({} as TariffContextProps);

export const TariffProvider: FC<React.PropsWithChildren> = ({ children }) => {
	const [tariffDataset, setTariffDataset] = useState<ITariffDataset | null>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const tariffRepository = new CTariffRepositoryImpl();

	const getTariffDataset = async (userProductId: number) => {
		setLoading(true);
		const tariffUseCase = new GetTariffDataSet(tariffRepository);
		const tariffDatasetResult = await tariffUseCase.call(userProductId);
		if (tariffDatasetResult.isRight()) {
			setTariffDataset(tariffDatasetResult.value);
		} else {
			setError(tariffDatasetResult.error);
		}
		setLoading(false);
	};

	const value = useMemo(() => ({
		tariffDataset,
		loading,
		getTariffDataset,
		error,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [tariffDataset,
		loading,
		error]);
	return <TariffContext.Provider value={value}>{children}</TariffContext.Provider>;
};

export default function useTariff() {
	return useContext(TariffContext);
}
