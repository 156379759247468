import { ApiResponse } from 'common/data/entities/api-response';
import { ContactApiImpl } from '../remote/contact-api-impl';
import { Either, Left, Right } from '@ajtec/either-typescript';
import { ICoorporate } from '../../domain/entities/coorporate';
import { IContactRepository } from '../../domain/repository/contact-repository';

export class CContactRepositoryImpl implements IContactRepository {
	contactApi = new ContactApiImpl();

	async createContact(params: ICoorporate): Promise<Either<string, ApiResponse<string>>> {
		try {
			const contact = await this.contactApi.createContact(params);
			return new Right(contact);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
