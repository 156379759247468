import { ApiResponse } from '@/common/data/entities/api-response';
import { IOrderRequestParams } from '../entities/order';
import { IOrderRepository } from '../repository/order-repository';
import { Either } from '@ajtec/either-typescript';

export class CreateOrder {
	repository: IOrderRepository;

	constructor(orderRepository: IOrderRepository) {
		this.repository = orderRepository;
	}

	call(params: IOrderRequestParams): Promise<Either<string, ApiResponse<string>>> {
		return this.repository.createOrder(params);
	}
}
