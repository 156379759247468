import Dimens from 'assets/dimens';
import { Box, Stack, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { BodyText } from 'common/presentation/components';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import CommunityFilters from './components/CommunityFilters';
import useCommunity from '../provider/community.provider';
import i18n from 'assets/translations';

const CommentList = () => {
	const { getArticles, articles } = useCommunity();
	const theme = useTheme();
	const navigate = useNavigate();
	const lang = i18n.language;

	const handleNavigate = (id: number) => {
		navigate(AppLinks.COMMUNITY_DETAIL.formatMap({ id }));
	};

	useEffect(() => {
		getArticles(lang);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
		}}
		>
			<CommunityFilters />
			<Stack
				direction="row"
				flexWrap="wrap"
				spacing={3}
				useFlexGap
				sx={{ mt: Dimens.FOOTER_PADDING_VERTICAL }}
			>
				{articles?.map((el) => (
					<Box key={el.id} sx={{ width: '450px', cursor: 'pointer' }} onClick={() => handleNavigate(el.id)}>
						<img style={{ borderRadius: Dimens.CARD_BORDER_RADIUS }} src={el.coverImage} alt={el.title} width="100%" />
						<Stack direction="row">
							<BodyText sx={{ color: theme.palette.primary.main }}>
								{ el?.tags.join(' | ')}

							</BodyText>
							<BodyText sx={{ ml: 2 }}>
								|
							</BodyText>
							<BodyText sx={{ ml: 2 }}>
								{ el?.publishedDate}
							</BodyText>
						</Stack>
						<BodyText sx={{
							fontSize: Dimens.BODY_TEXT_ML,
							fontWeight: Dimens.FONT_WEIGHT_LIGHTER,
						}}
						>
							{el?.title}
						</BodyText>
					</Box>
				))}
			</Stack>
		</Box>
	);
};

export default CommentList;
