import { Either } from '@ajtec/either-typescript';
import { EnergyConsumptionParams, IEnergyConsumption } from '../entities/energy-consuption';
import { IDeviceDetailRepository } from '../repository/device-detail-repository';

export class GetEnergyConsumption {
	repository: IDeviceDetailRepository;

	constructor(deviceDetailRepository: IDeviceDetailRepository) {
		this.repository = deviceDetailRepository;
	}

	call(params: EnergyConsumptionParams): Promise<Either<string, IEnergyConsumption>> {
		return this.repository.getEnergyConsumption(params);
	}
}
