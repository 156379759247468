import {
	Box, CssBaseline, Theme, ThemeProvider, createTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { getDesignTokens } from './utils/theme.utils';

import type {} from '@mui/lab/themeAugmentation';

const ClientAreaMain = () => {
	const [theme, setTheme] = useState<Theme>();
	// const navigate = useNavigate();
	useEffect(() => {
		getDesignTokens('client').then((th) => {
			setTheme(createTheme(th));
		});
		// window.addEventListener('popstate', () => {
		// 	navigate(1);
		// });
	}, []);
	if (!theme) return <div />;
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box>
				<Outlet />
			</Box>
		</ThemeProvider>
	);
};

export default ClientAreaMain;
