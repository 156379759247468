enum AppLinks {
	MAIN = '/',
	LANDING = '/',
	COMMUNITY = '/community',
	COMMUNITY_DETAIL = '/community/detail/:id',
	CONTACT_US = '/contact-us',
	PRODUCT = '/products',
	MOBILE_APP = '/mobile-app',
	CLIENT_AREA = '/client-area',
	CLIENT_AREA_LOGIN = '/client-area/login',
	CLIENT_AREA_FORGOT_PASSWORD = '/client-area/forgot-password',
	CHECKOUT_PAGE = '/checkout',
	FINALIZE_ORDER = '/finalize-order',
	CHECKOUT_SUCCESS = '/checkout-success',
	CLIENT_AREA_AUTH = '/client-area/cliente',
	CLIENT_AREA_DEVICE_LIST = '/client-area/cliente/devices',
	CLIENT_AREA_DEVICE_DETAIL = '/client-area/cliente/devices/device-detail',
	CLIENT_AREA_BILLING= '/client-area/cliente/devices/device-detail/billing',
	CLIENT_AREA_TARIFF = '/client-area/cliente/devices/device-detail/tariff',

	COOKIE_POLICY = '/cookie-policy',
}

export default AppLinks;
