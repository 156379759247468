import {
	Alert,
	Avatar,
	Badge, Box, Collapse, Divider, IconButton, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import { NotificationsActive } from '@mui/icons-material';
import useAuth from 'features/client-login/presentation/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import BodyText from '../body-text/BodyText';
import AlertItem from '../alert-item/AlertItem';

const UserCard = () => {
	const theme = useTheme();
	const { user, alerts } = useAuth();
	const { t } = useTranslation('translations');

	const [showAlerts, setShowAlerts] = React.useState(false);

	const handleAlertsClick = () => {
		setShowAlerts((prev) => !prev);
	};

	return (
		<Box sx={{
			width: '100%',
			maxHeight: '30%',
			// Scroll on overflow and hide scrollbar
			overflowY: 'scroll',
			'&::-webkit-scrollbar': {
				display: 'none',
			},
			p: Dimens.CLIENT_AREA_USER_CARD_PADDING,
			borderRadius: {
				md: Dimens.CLIENT_AREA_USER_CARD_BORDER_RADIUS,
				xs: Dimens.CLIENT_AREA_USER_CARD_BORDER_RADIUS_MOBILE,
			},
			bgcolor: theme.palette.background.paper,
			mt: { md: 0, xs: Dimens.CLIENT_AREA_PADDING_BOTTOM },
		}}
		>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Avatar
					src={user?.profileImage}
					sx={{
						width: Dimens.CLIENT_AREA_USER_CARD_AVATAR_SIZE,
						height: Dimens.CLIENT_AREA_USER_CARD_AVATAR_SIZE,
					}}
				/>
				<Box sx={{ overflow: 'hidden' }}>
					<BodyText sx={{
						fontSize: Dimens.BODY_TEXT_ML,
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
					}}
					>
						{user?.name}
					</BodyText>
					<BodyText sx={{
						fontSize: { md: Dimens.BODY_TEXT_MS, xs: Dimens.BUTTON_TEXT_MOBILE },
						fontWeight: Dimens.FONT_WEIGHT_LIGHT,
					}}
					>
						{user?.email}
					</BodyText>
				</Box>
				<Badge badgeContent={alerts.length} color="secondary">
					<IconButton color="inherit" onClick={handleAlertsClick}>
						<NotificationsActive />
					</IconButton>
				</Badge>
			</Stack>
			<Collapse in={showAlerts}>
				<Divider sx={{ mt: 2, mb: 2 }} />
				{alerts.length === 0 && (
					<Alert severity="error">
						{t('no_alerts_to_show')}
					</Alert>
				)}
				{alerts.map((alert) => (
					<AlertItem alert={alert} />
				))}
			</Collapse>
		</Box>
	);
};

export default UserCard;
