/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	Theme,
	ThemeProvider,
	createTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState } from 'react';
import DefaultButton from '../default-button/DefaultButton';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { getDesignTokens } from 'utils/theme.utils';

const Transition = React.forwardRef((
	props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

interface CookiesDialogProps {
	show: boolean;
	setShow: () => void
}

const CookiesDialog: React.FC<CookiesDialogProps> = (props) => {
	const { show, setShow } = props;
	const { t } = useTranslation('translations');

	const handleConsentClick = () => {
		LocalStorageWrapper.set(LocalStorageKeys.COOKIES, true);
		setShow();
	};

	const [theme, setTheme] = useState<Theme>();

	useEffect(() => {
		getDesignTokens('main').then((th) => {
			setTheme(createTheme(th));
		});
	}, []);

	if (!theme) return <div />;

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={show}
				TransitionComponent={Transition}
				maxWidth="xl"
				keepMounted // remove this line
				aria-describedby="alert-dialog-slide-description"
				sx={{ '.MuiDialog-container': { alignItems: 'flex-end !important' } }}
			>
				{/* add this line */}
				<DialogTitle color="primary">Cookies</DialogTitle>
				{/* add this line */}
				<DialogContent>
					{/* add this line */}
					<DialogContentText id="alert-dialog-slide-description" color="primary">
						{t('cookie_policy_info')}
					</DialogContentText>
				</DialogContent>
				{/* add this line */}
				<DialogActions>
					{/* add this line */}
					<DefaultButton endIcon={<Close />} onClick={setShow}>{t('reject')}</DefaultButton>
					<DefaultButton onClick={handleConsentClick}>{t('consent')}</DefaultButton>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	);
};

export default CookiesDialog;
