import Dimens from 'assets/dimens';
import React from 'react';
import { BodyText, TitleText } from 'common/presentation/components';
import { Box, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	watch4, watch5, watch6,
} from 'assets/images/user-stories';
import {
	iokeeMonoFront,
	iokeeMonoTopLeft,
	iokeeMonoTopRight,
	iokeeTriFront,
	iokeeTriTopLeft,
	iokeeTriTopRight,
} from 'assets/images/iokee-images';

const HowIokeeWorks = () => {
	const { t } = useTranslation('translations');

	return (
		<Stack
			direction="column"
			sx={{
				justifyContent: 'center',
				height: {
					md: Dimens.ABOUT_IOKEE_SECTION,
				},
				ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			spacing={2}
			component="section"
		>
			<Grid
				container
				spacing={{ md: 5, xs: 0 }}
				sx={{
					pt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE },
				}}
			>
				<Grid item xs={12} md={5}>
					<TitleText
						sx={{
							fontWeight: Dimens.FONT_WEIGHT_BOLD,
							fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE },
						}}
					>
						{t('how_iokee_works').toUpperCase()}
					</TitleText>
				</Grid>
				<Grid item xs={12} md={7}>
					<BodyText size={Dimens.BODY_TEXT_L} sx={{ fontWeight: Dimens.FONT_WEIGHT_LIGHT }} dangerouslySetInnerHTML={{ __html: t('how_iokee_works_info') }} />
				</Grid>
			</Grid>
			<Grid
				container
				spacing={{ md: 5, xs: 0 }}
				sx={{
					pt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE },
				}}
			>
				<Grid item xs={12} md={5}>
					<BodyText
						size={Dimens.BODY_TEXT_XL}
						sx={{
							fontStyle: 'italic',
							fontWeight: Dimens.FONT_WEIGHT_LIGHT,
						}}
					>
						{t('the_key_features').toUpperCase()}
					</BodyText>
				</Grid>
				<Grid item xs={12} md={7}>
					<BodyText size={Dimens.BODY_TEXT_L_MOBILE} sx={{ fontWeight: Dimens.FONT_WEIGHT_LIGHT }}>
						{t('the_key_features_info')}
					</BodyText>
					<BodyText size={Dimens.BODY_TEXT_L_MOBILE} sx={{ fontWeight: Dimens.FONT_WEIGHT_LIGHT }}>
						{t('the_key_features_info_one')}
					</BodyText>
					<BodyText size={Dimens.BODY_TEXT_L_MOBILE} sx={{ fontWeight: Dimens.FONT_WEIGHT_LIGHT }}>
						{t('the_key_features_info_two')}
					</BodyText>
					<BodyText size={Dimens.BODY_TEXT_L_MOBILE} sx={{ fontWeight: Dimens.FONT_WEIGHT_LIGHT }}>
						{t('the_key_features_info_three')}
					</BodyText>
				</Grid>
			</Grid>
			<Box sx={{ pt: { md: Dimens.TITLE_BODY_SPACING, xs: Dimens.TITLE_BODY_SPACING_MOBILE } }}>
				<Stack
					direction="row"
					spacing={2}
					sx={{

						overflowX: 'scroll',
						'&::-webkit-scrollbar': { display: 'none' },
						scrollbarWidth: 'none',
						msOverflowStyle: 'none',
						zindex: 1,
					}}
				>
					<img src={iokeeMonoFront} width="25%" alt="Watch" />
					<img src={iokeeMonoTopRight} width="25%" alt="Watch" />
					<img src={iokeeMonoTopLeft} width="25%" alt="watch" />
					<img src={iokeeTriFront} width="25%" alt="Watch" />
					<img src={iokeeTriTopRight} width="25%" alt="Watch" />
					<img src={iokeeTriTopLeft} width="25%" alt="Watch" />
				</Stack>
			</Box>

		</Stack>
	);
};

export default HowIokeeWorks;
