/* eslint-disable react/no-unstable-nested-components */
import {
	Navigate,
	Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import React, { useEffect } from 'react';
import LandingPage from 'features/landing/presentation';
import AppLinks from 'assets/applinks.routes';
import { CommunityPage } from './features/community/presentation';
import CommunityDetail from './features/community/presentation/community-detail';
import ProductPage from './features/products/presentation';
import Main from './Main';
import ClientAreaMain from './ClientAreaMain';
import ClientLogin from './features/client-login/presentation/login-page';
import DeviceList from './features/client-device-list/presentation';
import DeviceDetail from './features/client-device-detail/presentation';
import ClientTariff from './features/client-tariff/presentation';
import ClientBilling from './features/client-billing/presentation';
import useAuth from './features/client-login/presentation/provider/auth.provider';
import ClientAreaAuthenticated from './ClientAreaAuthenticated';
import MobileAppPage from './features/mobile-app/presentation';
import ContactUs from './features/contact-us/presentation';
import FinalizeOrder from './features/checkout/presentation/finalize-order';
import ProductSummary from './features/checkout/presentation/cart-page/ProductSummary';
import CheckoutSuccess from './features/checkout/presentation/checkout-success';
import ForgotPassword from './features/client-login/presentation/forgot-password';

const AppRouter = () => {
	const RequireAuth = ({ children }: { children: JSX.Element }) => {
		const { user } = useAuth();
		const location = useLocation();
		const navigate = useNavigate();
		useEffect(() => {
			if (user) {
				if (location.pathname.includes('client-area')) {
					navigate(AppLinks.CLIENT_AREA_DEVICE_LIST);
				}
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [user]);

		if (!user) {
			return <Navigate to={AppLinks.CLIENT_AREA_LOGIN} state={{ from: location }} replace />;
		}

		return children;
	};
	return (
		<Routes>
			{/* Add your routes here */}
			<Route path={AppLinks.MAIN} element={<Main />}>
				<Route path={AppLinks.LANDING} element={<LandingPage />} />
				<Route path={AppLinks.COMMUNITY} element={<CommunityPage />} />
				<Route path={AppLinks.COMMUNITY_DETAIL} element={<CommunityDetail />} />
				<Route path={AppLinks.PRODUCT} element={<ProductPage />} />
				<Route path={AppLinks.CHECKOUT_PAGE} element={<ProductSummary />} />
				<Route path={AppLinks.MOBILE_APP} element={<MobileAppPage />} />
				<Route path={AppLinks.CONTACT_US} element={<ContactUs />} />
				<Route path={AppLinks.FINALIZE_ORDER} element={<FinalizeOrder />} />
				<Route path={AppLinks.CHECKOUT_SUCCESS} element={<CheckoutSuccess />} />
			</Route>
			<Route path={AppLinks.CLIENT_AREA} element={<ClientAreaMain />}>
				<Route path={AppLinks.CLIENT_AREA_LOGIN} element={<ClientLogin />} />
				<Route path={AppLinks.CLIENT_AREA_FORGOT_PASSWORD} element={<ForgotPassword />} />
				<Route
					path={AppLinks.CLIENT_AREA_AUTH}
					element={(
						<RequireAuth>
							<ClientAreaAuthenticated />
						</RequireAuth>
					)}
				>
					<Route path={AppLinks.CLIENT_AREA_DEVICE_LIST} element={<DeviceList />} />
					<Route path={AppLinks.CLIENT_AREA_DEVICE_DETAIL} element={<DeviceDetail />} />
					<Route path={AppLinks.CLIENT_AREA_TARIFF} element={<ClientTariff />} />
					<Route path={AppLinks.CLIENT_AREA_BILLING} element={<ClientBilling />} />
				</Route>
			</Route>
		</Routes>
	);
};

export default AppRouter;
