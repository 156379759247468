import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import { Endpoints } from 'assets/Endpoints';
import axios, { AxiosRequestConfig } from 'axios';
import { IUser } from 'features/client-login/domain/entities/user';

const { BASE_URL } = Endpoints;

const axiosConfig = {
	baseURL: BASE_URL,
};

export const $axios = axios.create(axiosConfig);

const request = async (config: AxiosRequestConfig) => {
	const user = LocalStorageWrapper.get<IUser>(LocalStorageKeys.USER);
	// const lang = LocalStorageWrapper.get<string>(LocalStorageKeys.LOCALE)?.substring(0, 2);
	const bearerToken = user?.authenticationToken;

	config.headers = { // eslint-disable-line no-param-reassign
		...config.headers,
		'Access-Control-Allow-Origin': BASE_URL,
		// Lang: lang ?? i18next.language.substring(0, 2),
		Accept: 'application/json',
		...(bearerToken
			? {
				Authorization: `Bearer ${bearerToken}`,
			}
			: {}),
	};

	/** CHECK IF THE USER IS UNAUTHORIZED AND REFRESHE THE TOKEN */
	/** AXIOS RESPONSE INTERCEPTOR */
	// $axios.interceptors.response.use(
	// 	(response) => response,
	// 	async (error: AxiosError) => {
	// 		const originalRequest = error.config;
	// 		throw error;
	// 	},
	// );

	const returnValue = await $axios({ ...config });
	return returnValue;
};

export const reqGet = async (config: AxiosRequestConfig) => request({
	method: 'GET',
	...config,
});

export const reqPost = async (config: AxiosRequestConfig) => request({
	method: 'POST',
	...config,
});
export const reqPut = async (config: AxiosRequestConfig) => request({
	method: 'PUT',
	...config,
});

export const reqDelete = async (config: AxiosRequestConfig) => request({
	method: 'DELETE',
	...config,
});

export default request;
