import React from 'react';
import { LanguageProvider } from 'common/presentation/providers/language.provider';
import './app.css';
import AppRouter from './router';
import {} from './utils/extensions/string.extensions';
import { AuthProvider } from './features/client-login/presentation/provider/auth.provider';
import { FeedbackProvider } from './common/presentation/providers/feedback.provider';
import { CartProvider } from './common/presentation/providers/cart.provider';
import LocalStorageWrapper, { LocalStorageKeys } from './utils/storage.utils';
import CookiesDialog from './common/presentation/components/cookies-dialog/CookiesDialog';

const App = () => {
	const cookie = LocalStorageWrapper.get(LocalStorageKeys.COOKIES);
	const [show, setShow] = React.useState(cookie ?? false);

	const handleShow = () => {
		setShow(true);
	};

	return (
		<FeedbackProvider>
			<LanguageProvider>
				<AuthProvider>
					<CookiesDialog show={!show} setShow={handleShow} />
					<CartProvider>
						<AppRouter />
					</CartProvider>
				</AuthProvider>
			</LanguageProvider>
		</FeedbackProvider>
	);
};

export default App;
