import Dimens from 'assets/dimens';
import {
	Box, Stack, TextField, useMediaQuery, useTheme,
} from '@mui/material';
import React from 'react';
import { BodyText, TitleText } from 'common/presentation/components';
import { useTranslation } from 'react-i18next';
import useCommunity from '../provider/community.provider';

const CommunityTitle = () => {
	const { loading, highlightedComment, getComments } = useCommunity();
	const theme = useTheme();
	const isMd = useMediaQuery(() => theme.breakpoints.up('md'));
	const { t } = useTranslation('translations');
	React.useEffect(() => {
		getComments();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<section>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
			// mt: Dimens.SECTION_SPACING,
			}}
			>
				<Stack direction="row" alignItems="flex-end">
					<TitleText size={Dimens.TITLE_TEXT_L} sx={{ fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: t('iokee_community').toUpperCase() }} />
					<TextField size="small" variant="outlined" />
				</Stack>
				<BodyText sx={{ width: { md: '60%', xs: '100%' }, mt: Dimens.TITLE_SPACING }}>
					{t('iokee_community_info')}
				</BodyText>
				{loading && <h1>loading</h1>}
				{highlightedComment && (
					<Stack direction={{ md: 'row', xs: 'column' }} spacing={6} sx={{ mt: Dimens.TITLE_BODY_SPACING }} alignItems="center">
						<img src={highlightedComment?.image} alt="Community " width={isMd ? '50%' : '100%'} height="600px" />
						<Box>
							<Stack direction="row">
								<BodyText sx={{ color: theme.palette.primary.main }}>
									{t(highlightedComment.type.title).toUpperCase()}

								</BodyText>
								<BodyText sx={{ ml: 2 }}>
									|
								</BodyText>
								<BodyText sx={{ ml: 2 }}>
									{highlightedComment?.date}
								</BodyText>
							</Stack>
							<TitleText sx={{ mt: Dimens.TITLE_SPACING }}>
								{t(highlightedComment.title).toUpperCase()}
							</TitleText>
							<BodyText sx={{ fontSize: Dimens.BODY_TEXT_ML }}>
								{t(highlightedComment.description)}
							</BodyText>
						</Box>
					</Stack>
				)}
			</Box>
		</section>
	);
};

export default CommunityTitle;
