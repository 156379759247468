import { Either } from '@ajtec/either-typescript';
import { Article } from '../entities/article';
import { ICommunityRepository } from '../repositories/community-repository';

export class GetArticles {
	repository: ICommunityRepository;

	constructor(communityRepository: ICommunityRepository) {
		this.repository = communityRepository;
	}

	async call(lang: string): Promise<Either<string, Article[]>> {
		return this.repository.getArticles(lang);
	}
}
