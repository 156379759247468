import React from 'react';
import HeroSection from './HeroSection';
import AboutIokee from './AboutIokee';
import UserStories from './UserStories';
import ProductSection from './ProductSection';
import CommunitySection from './CommunitySection';
import GreenManifesto from './GreenManifesto';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';

const LandingPage = () => (
	<main className="container">
		<CommunityProvier>
			<HeroSection />
			<AboutIokee />
			<UserStories />
			<ProductSection />
			<GreenManifesto />
			<CommunitySection />
		</CommunityProvier>
	</main>
);

export default LandingPage;
