import Dimens from 'assets/dimens';
import React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

interface Props extends LoadingButtonProps {
	children: string
}
const ClientButton: React.FC<Props> = (props) => {
	const { children, sx } = props;
	return (
		<LoadingButton
			color="primary"
			variant="contained"
			size="small"
			sx={{
				pl: Dimens.BUTTON_PADDING_HORIZANTAL,
				pr: Dimens.BUTTON_PADDING_HORIZANTAL,
				pt: Dimens.CLIENT_BUTTON_VERTICAL,
				pb: Dimens.CLIENT_BUTTON_VERTICAL,
				borderRadius: 1.5,
				fontSize: { md: Dimens.BUTTON_TEXT, xs: Dimens.BUTTON_TEXT_MOBILE },
				// fontSize: { md: Dimens.TEXT_DEFAULT, xs: Dimens.TEXT_SMALL },
				// fontWeight: Dimens.TEXT_WEIGHT_NORMAL,
				...sx,
			}}
			{...props}
		>
			{children}
		</LoadingButton>
	);
};

export default ClientButton;
