import React from 'react';
import { SizedBox, TitleText } from 'common/presentation/components';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import TechnicalSpecsItem from './components/TechnicalSpecsItem';
import MonophaseHidden from './components/MonophaseHidden';

const TechnicalSpecification = () => {
	const { t } = useTranslation('translations');
	return (
		<Box
			id="technical-specification"
			sx={{
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
		>
			<TitleText
				sx={{
					fontStyle: 'italic',
					mb: {
						md: Dimens.TITLE_BODY_SPACING,
						xs: Dimens.TITLE_BODY_SPACING_MOBILE,
					},
					fontSize: { md: Dimens.TITLE_TEXT_L, xs: Dimens.TITLE_TEXT_L_MOBILE },
				}}
			>
				{t('technical_specifications').toUpperCase()}
			</TitleText>
			<TechnicalSpecsItem title={`${t('monophase')} AE-1PH`.toUpperCase()} hiddenItem={<MonophaseHidden />} />
			<SizedBox height={5} />
			<TechnicalSpecsItem title={`${t('triphase')} AE-3PH`.toUpperCase()} hiddenItem={<MonophaseHidden />} />
			<SizedBox height={5} />
		</Box>
	);
};

export default TechnicalSpecification;
