import Dimens from 'assets/dimens';
import {
	Card, Stack, useTheme,
} from '@mui/material';
import React from 'react';
import { BodyText } from 'common/presentation/components';

interface Props {
	title: string;
	icon: React.ReactNode;
	onClick: () => void
}

const DeviceOptionCard: React.FC<Props> = (props) => {
	const theme = useTheme();
	const { title, icon, onClick } = props;
	return (
		<Card
			onClick={onClick}
			sx={{
				maxWidth: Dimens.CLIENT_AREA_DEVICE_OPTION_CARD_WIDTH,
				height: Dimens.CLIENT_AREA_DEVICE_OPTION_CARD_HEIGHT,
				cursor: 'pointer',
				borderRadius: Dimens.CARD_BORDER_RADIUS,
				pl: Dimens.CLIENT_AREA_DEVICE_BUTTON_PADDING_HORIZANTAL,
				pr: Dimens.CLIENT_AREA_DEVICE_BUTTON_PADDING_HORIZANTAL,
			}}
		>
			<Stack direction="row" alignItems="center" sx={{ height: '100%' }}>
				<Stack
					sx={{
						height: Dimens.CLIENT_AREA_DEVICE_OPTION_ICON_SIZE,
						width: Dimens.CLIENT_AREA_DEVICE_OPTION_ICON_SIZE,
						borderRadius: Dimens.CLIENT_AREA_DEVICE_OPTION_ICON_SIZE,
						bgcolor: theme.palette.primary.main,
					}}
					alignItems="center"
					justifyContent="center"
				>
					{icon}
				</Stack>
				<BodyText sx={{ ml: '20px', fontSize: Dimens.BUTTON_TEXT }}>
					{title}
				</BodyText>
			</Stack>
		</Card>
	);
};

export default DeviceOptionCard;
