export enum Endpoints {
	// BASE_URL = 'http://192.168.12.11:5324/api/v1',
	BASE_URL= 'https://test.api.iokee.io/api/v1/',

	LOGIN = '/user/authenticate',
	FORGOT_PASSWORD = '/forgot-password',
	USER_PRODUCTS = '/user-products/my-user-products-full',
	ENERGY_CONSUMPTION = '/energy-consumption',
	TARIFF_DATASET = 'user-product/{0}/tariffs',
	ALERTS = '/notify-alerts',
	ENERGY_CONSUMPTION_PERIOD_YEAR = '/energy-consumption/annual',
	ENERGY_CONSUMPTION_PERIOD_MONTH = '/energy-consumption/monthly',
	ENERGY_CONSUMPTION_PERIOD_WEEK = '/energy-consumption/weekly',
	ORDER = '/client/orders',
	COORPORATE = '/client/requests/cooperate',
	JOIN_COMMUNITY = '/client/requests/community'
}
