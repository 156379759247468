/* eslint-disable no-console */
import React from 'react';
import {
	useTheme, Stack, Box, Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	BodyText, TitleText, CommunityForm, DefaultButton,
} from 'common/presentation/components';
import Dimens from 'assets/dimens';
import {
	Call, Mail,
} from 'assets/icons';
import useCart from 'common/presentation/providers/cart.provider';
import { ICartProduct } from 'common/domain/entities/cart-product';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import CartItem from '../components/CartItem';
import ProductSummaryCard from '../components/ProductSummaryCard';
import { CommunityProvier } from 'features/community/presentation/provider/community.provider';

const ProductSummary = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const navigate = useNavigate();

	const {
		cartProducts,
		getTotalPrice,
		removeFromCart,
		incrementProduct,
		decrementCart,
	} = useCart();

	const handleDeleteClick = (product: ICartProduct) => {
		removeFromCart(product);
	};

	const handleContinueShoppingCart = () => {
		navigate(AppLinks.PRODUCT);
	};

	const handleFinalizeClick = () => {
		navigate(AppLinks.FINALIZE_ORDER);
	};

	return (
		<CommunityProvier>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM, xs: Dimens.APP_BAR_MARGIN_BOTTOM_MOBILE },
			}}
			>
				<Stack
					direction={{ xs: 'column', md: 'row' }}
					columnGap={1}
					justifyContent="space-between"
					alignItems="flex-start"
					flexWrap="wrap"
				>
					<Stack width={{ xs: Dimens.CHECKOUT_CARD_WIDTH, md: Dimens.MD_CHECKOUT_CARD_AREA }}>
						<Stack direction="row" alignItems="center" justifyContent="space-between">
							<Box>
								<TitleText
									sx={{ fontSize: { md: Dimens.TITEL_TEXT_M, xs: Dimens.TITLE_TEXT_M_MOBILE } }}
									color={theme.palette.textMain.main}
								>
									{t('your_shopping_cart')}
								</TitleText>
							</Box>
							<Box>
								<BodyText>
									{cartProducts.length}
									{' '}
									{t('product_added')}
								</BodyText>
								{cartProducts.length > 0 && (
									<Box sx={{ display: { md: 'flex', xs: 'none' } }}>
										<BodyText sx={{ pr: 1 }}>
											{t('total')}
										</BodyText>
										<BodyText>
											{getTotalPrice().toFixed(2)}
											{' €'}
										</BodyText>
									</Box>
								)}
							</Box>
						</Stack>
						<Stack>
							{cartProducts.length === 0 && (
								<BodyText textAlign="center" sx={{ mt: 4, color: theme.palette.error.main }}>
									{t('no_cart_items')}
								</BodyText>
							)}
							{cartProducts.map((product) => (
								<CartItem
									title={product.name}
									image={product.image}
									price={product.price}
									originalPrice={product.originalPrice}
									storageFee={product.storageFee}
									freeStorageTime={product.freeStorageTime}
									count={product.qty}
									onDeleteClick={() => handleDeleteClick(product)}
									incrementProduct={() => incrementProduct(product)}
									decrementProduct={() => decrementCart(product)}
								/>
							))}
						</Stack>
					</Stack>
					<Stack
						width={{ xs: '100%', md: '35%' }}
						sx={{ mt: { md: 0, xs: 2 } }}
						justifyContent="flex-end"
						alignItems={{ md: 'flex-end', xs: 'center' }}
					>
						{cartProducts.length > 0 && (
							<ProductSummaryCard
								totalPrice={getTotalPrice()}
								onFinalizeClick={handleFinalizeClick}
								buttonText={t('make_contact_request')}
							/>
						)}
					</Stack>

				</Stack>
				<Grid container spacing={1} sx={{ marginTop: '50px' }}>
					<Grid item xs={12} md={4}>
						<BodyText sx={{ fontSize: { xs: '14px', md: Dimens.BODY_TEXT_XL } }}>
							{t('Contacte-nos').toUpperCase()}
						</BodyText>
						<Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
							<Call />
							<BodyText sx={{ paddingLeft: '10px' }}>
								{t('+ 351 934 934 934')}
							</BodyText>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
							<Mail />
							<BodyText sx={{ paddingLeft: '10px' }}>
								{t('iokee@iokeesupport.pt')}
							</BodyText>
						</Box>
					</Grid>

					<Grid item xs={12} md={4}>
						<BodyText sx={{ fontSize: '26px' }}>
							{t('Esqueceu-se de alguma coisa?').toUpperCase()}
						</BodyText>
						<Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
							<DefaultButton sx={{ p: 0, m: 0 }} variant="text" onClick={handleContinueShoppingCart}>
								{t('Continuar a comprar')}
							</DefaultButton>
						</Box>
					</Grid>

				</Grid>
			</Box>
			<CommunityForm />
		</CommunityProvier>
	);
};

export default ProductSummary;
