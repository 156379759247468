import Dimens from 'assets/dimens';
import { BodyText, TitleText } from 'common/presentation/components';
import {
	Box,
	Stack,
	Theme,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductCard from './components/product-card/ProductCard';
import { productSinglePhase, productmodel } from 'assets/3dmodel';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';

const ProductSection = () => {
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const [singleModalDimens, setSingleModalDimens] = React.useState({
		width: Dimens.MODEL_WIDTH_SINGLE_PHASE,
		height: Dimens.MODEL_HEIGHT_SINGLE_PHASE,
	});
	const [triPhaseModalDimens, setTriPhaseModalDimens] = React.useState({
		width: Dimens.MODEL_WIDTH,
		height: Dimens.MODEL_HEIGHT,
	});

	useEffectCustom(() => {
		if (!isMd) {
			setSingleModalDimens({
				width: Dimens.MODEL_WIDTH_SINGLE_PHASE_MOBILE,
				height: Dimens.MODEL_HEIGHT_SINGLE_PHASE_MOBILE,
			});
			setTriPhaseModalDimens({
				width: Dimens.MODEL_WIDTH_MOBILE,
				height: Dimens.MODEL_HEIGHT_MOBILE,
			});
		} else {
			setSingleModalDimens({
				width: Dimens.MODEL_WIDTH_SINGLE_PHASE,
				height: Dimens.MODEL_HEIGHT_SINGLE_PHASE,
			});
			setTriPhaseModalDimens({
				width: Dimens.MODEL_WIDTH,
				height: Dimens.MODEL_HEIGHT,
			});
		}
	}, [isMd]);

	return (
		<section>
			<Box sx={{
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: Dimens.SECTION_SPACING,
			}}
			>
				<TitleText
					size={Dimens.TITEL_TEXT_M}
					sx={{ fontWeight: Dimens.FONT_WEIGHT_BOLD }}
				>
					{t('the_products').toUpperCase()}
				</TitleText>
				<BodyText
					size={Dimens.BODY_TEXT_XL}
					sx={{ fontWeight: Dimens.FONT_WEIGHT_LIGHTER }}
				>
					{t('discover_technologies').toUpperCase()}
				</BodyText>
				<Stack direction={{ md: 'row', xs: 'column' }} spacing={2} justifyContent="space-between" sx={{ mt: Dimens.CARD_PADDING_LARGE }}>
					<ProductCard
						model={productmodel}
						title="energy_efficiency_controller_tri"
						info="energy_efficiency_controller_info"
						color={theme.palette.bgLight.main}
						id="iokee-three-phase"
						width={triPhaseModalDimens.width}
						height={triPhaseModalDimens.height}
					/>
					<ProductCard
						model={productSinglePhase}
						title="energy_efficiency_controller_mono"
						info="energy_efficiency_controller_info"
						color={theme.palette.bgLight.main}
						id="iokee-single-phase"
						width={singleModalDimens.width}
						height={singleModalDimens.height}
					/>
				</Stack>

			</Box>
		</section>
	);
};

export default ProductSection;
