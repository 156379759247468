/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/media-has-caption */
import {
	Box,
	useMediaQuery,
} from '@mui/material';
import React from 'react';
import Dimens from 'assets/dimens';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
// import CircularLabel from './components/circular-label/CircularLabel';
import './components/circular-label/index.css';

const HeroSection = () => {
	const isMd = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

	useEffectCustom(() => {
		const circle = document.getElementById('circle-container');
		const bottom = document.getElementById('bottom');
		const containerRect = bottom?.getBoundingClientRect();
		let initialCircleX = 0;
		let initialCircleY = 0;
		if (!initialCircleX && !initialCircleY) {
			initialCircleX = circle?.offsetLeft as number;
			initialCircleY = circle?.offsetTop as number;
		}

		const onMouseMove = (e: any) => {
			const { scrollY } = window;
			const mouseX = e.clientX - (containerRect?.left || 0);
			const mouseY = e.clientY - (containerRect?.top || 0);
			if (circle) {
				circle.style.position = 'absolute';
				circle.style.left = `${mouseX}px`;
				circle.style.top = `${mouseY + scrollY}px`;
			}
		};
		const handleMouseOver = () => {
			if (isMd) {
				document.addEventListener('mousemove', onMouseMove);
			}
		};

		const handleMouseOut = () => {
			document.removeEventListener('mousemove', onMouseMove);
			if (circle) {
				circle.style.left = `${initialCircleX}px`;
				circle.style.top = `${initialCircleY - 50}px`;
			}
		};

		bottom?.addEventListener('mouseover', handleMouseOver);
		bottom?.addEventListener('mouseout', handleMouseOut);

		return () => {
			bottom?.removeEventListener('mouseover', handleMouseOver);
			bottom?.removeEventListener('mouseout', handleMouseOut);
			document.removeEventListener('mousemove', onMouseMove);
		};
	}, []);

	return (
		<section>
			<Box sx={{
				ml: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				borderRadius: Dimens.VIDEO_BORDER_RADIUS,
				overflow: 'hidden',
				height: '90vh',
				display: 'flex',
			}}
			>
				<video width="100%" height="auto" autoPlay playsInline loop muted style={{ objectFit: 'cover', borderRadius: Dimens.VIDEO_BORDER_RADIUS }}>
					<source src="https://innovaj.blob.core.windows.net/iokee-videos/video_iokee.mp4" type="video/mp4" />
				</video>
				{/* <Stack sx={{
					position: 'absolute',
					zIndex: 1,
					margin: 0,
					width: '80%',
					textAlign: 'center',
					bottom: '0',
					left: '10%',
				}}
				>
					<Stack id="bottom" className="bottom-container">
						<CircularLabel />
					</Stack>
				</Stack> */}
			</Box>
		</section>
	);
};

export default HeroSection;
