import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import useLanguage from 'common/presentation/providers/language.provider';
import i18next from 'i18next';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: '70px',
	height: '33px',
	padding: '1px',
	'& .MuiSwitch-switchBase': {
		margin: '4px',
		padding: '1px',
		transform: 'translateX(0px)',
		'&.Mui-checked': {
			color: '#000',
			transform: 'translateX(25px)',
			'& .MuiSwitch-thumb:before': { content: "'PT'", fontWeight: '500' },
			'& + .MuiSwitch-track': {
				color: '#fff',
				border: `2px solid ${theme.palette.primary.main}`,
				opacity: 1,
				backgroundColor: theme.palette.background.default,
				content: "'PT'",
			},
		},
	},
	'& .MuiSwitch-thumb': {
		color: '#000',
		textAlign: 'center',
		width: '35px',
		height: '23px',
		'&:before': {
			fontWeight: '500',
			content: "'EN'",
			position: 'absolute',
			width: '100%',
			height: '100%',
			borderRadius: '20px',
			left: 0,
			top: 0,
			backgroundColor: theme.palette.primary.main,
		},
	},
	'& .MuiSwitch-track': {
		border: `2px solid ${theme.palette.primary.main}`,
		opacity: 1,
		backgroundColor: theme.palette.background.default,
		borderRadius: '16px',
		'&:before': {
			fontSize: '12px',
			color: '#fff',
			content: '"EN"',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
			height: 16,
			left: 7,
		},
		' &:after': {
			fontSize: '12px',
			color: '#fff',
			content: '"PT"',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
			height: 16,
			right: 9,
		},
	},
}));

const LanguageSelector = () => {
	const { changeLocale } = useLanguage();

	const handleChange = () => {
		const lang = i18next.language.substring(0, 2) === 'en' ? 'pt' : 'en';
		const locale = i18next.language.substring(0, 2) === 'en' ? 'ptPT' : 'enUS';
		i18next.changeLanguage(lang);
		changeLocale(locale);
	};

	return (
		<MaterialUISwitch checked={i18next.language.substring(0, 2) === 'pt'} onChange={handleChange} />
	);
};

export default LanguageSelector;
