import { BodyText, DeviceCard } from 'common/presentation/components';
import {
	Box, CircularProgress, Stack, useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useAuth from 'features/client-login/presentation/provider/auth.provider';
import LocalStorageWrapper, { LocalStorageKeys } from 'utils/storage.utils';
import useDevices from './provider/device.provider';
import { IDevice } from '../domain/entities/device';

const DeviceListPage = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { user } = useAuth();
	const handleDeviceClick = (device: IDevice) => {
		LocalStorageWrapper.set(LocalStorageKeys.DEVICE, device);
		navigate(AppLinks.CLIENT_AREA_DEVICE_DETAIL);
	};
	const {
		getUserProducts, devices,
		loading,
		error,
	} = useDevices();

	useEffect(() => {
		getUserProducts();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box>
			<BodyText sx={{
				fontSize: { md: Dimens.BODY_TEXT_XL, xs: Dimens.BODY_TEXT_XL_MOBILE },
				fontWeight: Dimens.FONT_WEIGHT_BOLD,
			}}
			>
				{t('client_area_welcome_message').format(user?.name)}
			</BodyText>
			<BodyText sx={{
				fontSize: {
					md: Dimens.BODY_TEXT_M,
					xs: Dimens.BODY_TEXT_M_MOBILE,
				},
				mt: 1,
				color: theme.palette.textLight.main,
			}}
			>
				{t('monitor_energy_consumption')}
			</BodyText>
			<BodyText sx={{
				fontSize: {
					md: Dimens.BODY_TEXT_M,
					xs: Dimens.BODY_TEXT_M_MOBILE,
				},
				color: theme.palette.textLight.main,
			}}
			>
				{t('get_access_to_all_reports')}
			</BodyText>
			<Stack direction={{ md: 'row', xs: 'column' }} sx={{ mt: { md: 10, xs: 3 } }}>
				<BodyText sx={{
					fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE },
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
				}}
				>
					{t('your_analyzers').toUpperCase()}
				</BodyText>
				<BodyText sx={{
					fontSize: { md: Dimens.BODY_TEXT_M, xs: Dimens.BODY_TEXT_M_MOBILE }, ml: { md: 2, xs: 0 }, fontStyle: 'italic',
				}}
				>
					{t('choose_analyzer_to_consult')}
				</BodyText>
			</Stack>
			<Stack direction="row" useFlexGap flexWrap="wrap" spacing={3} columnGap={3} sx={{ mt: 2.5 }}>
				{loading && <CircularProgress />}
				{error && <BodyText>{error}</BodyText>}
				{devices && devices.map((el) => (
					<DeviceCard
						key={el.id}
						title={el.name}
						deviceName={`${el?.board?.productCode}${el?.board?.serialNumber}`}
						address={el?.address}
						onClick={() => handleDeviceClick(el)}
					/>
				))}
			</Stack>
		</Box>
	);
};

export default DeviceListPage;
