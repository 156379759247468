import { Either, Left, Right } from '@ajtec/either-typescript';
import { ILoginRepository } from '../../domain/repository/login-repository';
import { LoginParams } from '../../domain/usecases/login';
import { IUser } from '../../domain/entities/user';
import { LoginApiImpl } from '../remote/login-api-impl';
import { IAlert } from '../../domain/entities/alert';

export class CLoginRepositoryImpl implements ILoginRepository {
	loginApi = new LoginApiImpl();

	async login(params: LoginParams): Promise<Either<string, IUser>> {
		try {
			const products = await this.loginApi.login(params);
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getAlerts(): Promise<Either<string, IAlert[]>> {
		try {
			const products = await this.loginApi.getAlerts();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async forgotPassword(email: string): Promise<Either<string, string>> {
		try {
			const forgotPassword = await this.loginApi.forgotPassword(email);
			return new Right(forgotPassword);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
