import { logoBlack } from 'assets/icons';
import {
	BodyText, ClientButton, SizedBox, TitleText,
} from 'common/presentation/components';
import {
	Box, TextField, useMediaQuery, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../provider/auth.provider';
import { validateEmail } from 'utils/validation';

const ForgotPasswordForm = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const firstRenderRef = React.useRef(true);

	const { loading, forgotPassword, forgotPasswordResp } = useAuth();

	const [email, setEmail] = React.useState('');
	const [emailError, setEmailError] = React.useState('');

	React.useEffect(() => {
		if (!firstRenderRef.current) {
			if (!validateEmail(email)) {
				setEmailError(t('email_error'));
			} else {
				setEmailError('');
			}
		}
		return () => { firstRenderRef.current = false; };
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email]);

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		setEmailError('');
	};

	const handleSubmitClick = (event: React.FormEvent) => {
		event.preventDefault();
		forgotPassword(email);
	};

	return (
		<Box sx={{
			pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
			pr: { md: 0, xs: Dimens.PAGE_PADDING_MOBILE },
			pt: { md: Dimens.FOOTER_PADDING_VERTICAL, xs: '0px' },
			pb: { xs: Dimens.PAGE_PADDING_MOBILE, md: 0 },
			width: { md: '40%', xs: '100%' },
		}}
		>
			{isMd && <img src={logoBlack} alt="Logo" />}
			<TitleText sx={{
				fontSize: Dimens.TITEL_TEXT_M,
				color: theme.palette.textMain.main,
				fontWeight: Dimens.FONT_WEIGHT_BOLDER,
				mt: { md: Dimens.APP_BAR_MARGIN_BOTTOM },
			}}
			>
				{t('forgot_your_password')}
			</TitleText>
			<BodyText sx={{ fontSize: Dimens.BODY_TEXT_XL, mt: 2 }}>
				{t('enter_your_email_for_link').toUpperCase()}
			</BodyText>
			<form onSubmit={handleSubmitClick}>
				<TextField value={email} variant="outlined" onChange={handleEmailChange} error={Boolean(emailError)} helperText={emailError} fullWidth label="E-mail" type="email" sx={{ mt: { md: 10, xs: 2 } }} />
				{forgotPasswordResp && <BodyText>{forgotPasswordResp}</BodyText>}
				<SizedBox height={3} />
				<ClientButton loading={loading} fullWidth type="submit">{t('enter')}</ClientButton>
			</form>
		</Box>
	);
};

export default ForgotPasswordForm;
