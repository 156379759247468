import Dimens from 'assets/dimens';
import { BodyText, TitleText } from 'common/presentation/components';
import {
	Box, Grid, Stack, useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import DefaultButton from 'common/presentation/components/default-button/DefaultButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applinks.routes';
import useCommunity from 'features/community/presentation/provider/community.provider';
import i18n from 'assets/translations';

interface Props {
	dateColor?: string;
	tagColor?: string;
	title: string;
	date: string
}

const CategoryItem: React.FC<Props> = (props) => {
	const theme = useTheme();
	const {
		dateColor,
		title,
		date,
		tagColor,
	} = props;
	return (
		<Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
			<BodyText
				size={Dimens.BODY_TEXT_S}
				sx={{
					color: tagColor ?? theme.palette.primary.main,
					fontWeight: Dimens.FONT_WEIGHT_BOLD,
				}}
			>
				{title.toUpperCase()}
			</BodyText>
			<BodyText
				size={Dimens.BODY_TEXT_S}
				sx={{
					color: dateColor ?? theme.palette.background.default,
				}}
			>
				|
				{' '}
				{date}
			</BodyText>
		</Stack>
	);
};

const UserStories = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const navigate = useNavigate();
	const lang = i18n.language;
	const { getArticles, articles } = useCommunity();

	useEffect(() => {
		getArticles(lang);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleReadMoreClick = () => {
		navigate(AppLinks.COMMUNITY);
	};

	const handleNavigate = (id: number) => {
		navigate(AppLinks.COMMUNITY_DETAIL.replace(':id', `${id}`));
	};
	return (
		<section>
			<Box sx={{
				pl: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				pr: { md: Dimens.PAGE_PADDING, xs: Dimens.PAGE_PADDING_MOBILE },
				mt: Dimens.SECTION_SPACING,
			}}
			>
				<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" alignItems="center">
					<TitleText size={Dimens.TITLE_TEXT_L} fontStyle="italic" dangerouslySetInnerHTML={{ __html: t('user_stories').toUpperCase() }} />
					<DefaultButton onClick={handleReadMoreClick}>
						{t('read_more_cases')}
					</DefaultButton>
				</Stack>
				{articles.length === 3
				&& (
					<Grid container spacing={2} sx={{ mt: Dimens.TITLE_SPACING }}>
						<Grid item md={4} xs={12}>
							<Box
								sx={{
									width: '100%',
									height: '82vh',
									background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 20%, transparent 30%, rgba(215, 215, 215, 0) 90%), url(${articles[0].coverImage}) no-repeat`,
									borderRadius: Dimens.CARD_BORDER_RADIUS,
									pl: Dimens.TITLE_SPACING,
									pt: Dimens.TITLE_SPACING,
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									cursor: 'pointer',
								}}
								onClick={() => handleNavigate(articles[0].id)}
							>
								<CategoryItem
									title={articles[0].tags[0]}
									tagColor={theme.palette.background.default}
									date={articles[0].publishedDate}
								/>
								<BodyText
									size={Dimens.BODY_TEXT_L}
									sx={{ width: '80%', color: theme.palette.background.default, fontWeight: Dimens.FONT_WEIGHT_BOLD }}
								>
									&quot;
									{articles[0].title}
									&quot;
								</BodyText>
							</Box>
						</Grid>
						<Grid item md={8} xs={12}>
							<Stack
								direction="column"
								justifyContent="center"
								sx={{
									width: '100%',
									height: '40vh',
									background: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, transparent 50%, rgba(215, 215, 215, 0) 90%), url(${articles[1].coverImage}) no-repeat`,
									borderRadius: Dimens.CARD_BORDER_RADIUS,
									pl: Dimens.TITLE_SPACING,
									pb: Dimens.TITLE_SPACING,
									backgroundSize: 'cover',
									cursor: 'pointer',
								}}
								onClick={() => handleNavigate(articles[1].id)}
							>
								<CategoryItem
									tagColor={theme.palette.background.default}
									title={articles[1].tags[0]}
									date={articles[1].publishedDate}
								/>
								<BodyText
									size={Dimens.BODY_TEXT_L}
									sx={{
										width: {
											xl: '20%',
											lg: '20%',
											md: '40%',
											xs: '50%',
										},
										color: theme.palette.background.default,
										fontWeight: Dimens.FONT_WEIGHT_BOLD,
									}}
								>
									&quot;
									{articles[1].title}
									&quot;
								</BodyText>
							</Stack>
							<Stack
								direction="column"
								justifyContent="center"
								sx={{
									mt: 2,
									width: '100%',
									height: '40vh',
									background: `linear-gradient(to right, rgba(0, 0, 29, 0.5) 30%, transparent 90%, rgba(215, 215, 215, 0) 90%), url(${articles[2].coverImage}) no-repeat`,
									borderRadius: Dimens.CARD_BORDER_RADIUS,
									pl: Dimens.TITLE_SPACING,
									pb: Dimens.TITLE_SPACING,
									backgroundSize: 'cover',
									cursor: 'pointer',
								}}
								onClick={() => handleNavigate(articles[2].id)}
							>
								<CategoryItem
									tagColor={theme.palette.textMain.main}
									dateColor={theme.palette.textMain.main}
									title={articles[2].tags[0]}
									date={articles[2].publishedDate}
								/>
								<BodyText
									size={Dimens.BODY_TEXT_L}
									sx={{
										color: theme.palette.textMain.main,
										fontWeight: Dimens.FONT_WEIGHT_BOLD,
										width: {
											xl: '20%',
											lg: '20%',
											md: '40%',
											xs: '50%',
										},
									}}
								>
									&quot;
									{articles[2].title}
									&quot;
								</BodyText>
							</Stack>
						</Grid>
					</Grid>
				)}
			</Box>
		</section>
	);
};

export default UserStories;
