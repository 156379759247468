import Dimens from 'assets/dimens';
import { Stack, Box, useTheme } from '@mui/material';
import React from 'react';
import { BodyText } from 'common/presentation/components';

const filters = [
	'All',
	'SOLAR PAINELS',
	'Analizer',
	'wallbox',
];

const CommunityFilters = () => {
	const theme = useTheme();
	return (
		<Stack
			direction="row"
			spacing={4}
			sx={{
				overflowX: 'scroll',
				'&::-webkit-scrollbar': { display: 'none' },
				scrollbarWidth: 'none',
				msOverflowStyle: 'none',
			}}
		>
			{filters.map((el) => (
				<Box
					component="span"
					sx={{
						pl: Dimens.BUTTON_PADDING_HORIZANTAL,
						pr: Dimens.BUTTON_PADDING_HORIZANTAL,
						border: `1px solid ${theme.palette.primary.main}`,
						borderRadius: '30px',
						pt: '15px',
						pb: '15px',
						cursor: 'pointer',
					}}
				>
					<BodyText sx={{
						fontSize: Dimens.BODY_TEXT_S,
						fontWeight: Dimens.FONT_WEIGHT_BOLD,
						color: theme.palette.primary.main,
					}}
					>
						{el.toUpperCase()}
					</BodyText>
				</Box>
			))}
		</Stack>
	);
};

export default CommunityFilters;
